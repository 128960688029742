import styled, { css } from 'styled-components';

const CellContainerStyled = styled.li`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: ${(props) => props.tableSticky ? '0px' : `1px solid ${props.theme.colors.grey[400]}`}
  background-color: ${(props) => (props.isSelected ? props.theme.colors.grey[300] : 'transparent')};

  &:hover {
    cursor: ${(props) => (props.tableSticky ? 'default' : 'not-allowed')}
  }

  ${(props) =>
    !props.isTransferring && !props.tableSticky &&
    css`
      &:active {
        background-color: ${props.theme.colors.grey[300]};
      }
      &:hover {
        background-color: ${props.theme.colors.grey[400]};
        padding-left: 18px;
        cursor: pointer;
      }
    `};

  .label-type-chat {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #a6a8a8;
  }

  .img-icon-sf {
    height: 30px;
  }
  .icon-channel {
    margin-right: 1.25em;
  }
  .cell-content {
    display: flex;
    flex: 3;
    align-items: center;
    justify-content: flex-start;
    padding: 0.75em 0.75em 0.75em 0.75em;
    .image-round {
      width: 3em;
      height: 3em;
      border-radius: 1.5em;
    }

    .transfer-icon-holder {
      margin-right: 32px;
    }
  }

  .chat-cell {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    .events-wrapper {
      display: flex;
      align-items: center;
    }
  }

  .div_btn_sf {
    padding: 14px 0 14px 14px;
    cursor: pointer;
  }

  .button_green {
    padding: 5px;
    border-radius: 4px;
    outline: none;
    border-width: 0;
    background-color: transparent;
    color: white;
    cursor: pointer;
    &:hover {
      color: white;
      opacity: 0.75;
      cursor: pointer;
    }
    &:active {
      background-color: transparent;
    }
  }

  .button_red {
    border-radius: 4px;
    outline: none;
    border-width: 0;
    cursor: pointer;
    background-color: transparent;
    color: white;
    &:hover {
      color: white;
      opacity: 0.75;
      cursor: pointer;
    }
    &:active {
      background-color: transparent;
    }
  }

  @media (max-width: ${({ theme }) => theme.bp.xl}px) {
    .cell-content {
      padding: 0.75em 0.75em 0.75em 0;
    }
  }
`;

export { CellContainerStyled };
