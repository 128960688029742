import styled from 'styled-components';

const ButtonStyled = styled.div`
  display: flex;
  height: 60px;
  position: relative;
  left: 0;
  bottom: 0;
  padding: 0px 0px;
  justify-content: space-between;
  align-items: center;

  .status-holder {
    margin: 0;
    height: 30px;
    padding-right: 15px;

    .my-status-label {
      font-size: 12px;
      margin-bottom: 4px;
    }

    .my-status-value {
      font-size: 15px;
      color: ${({ redButton }) => (!redButton ? 'green' : 'red')};
    }
  }

  .chat-button {
    padding: ${({ padding }) => padding ? `${padding}px` : '8px 12px 8px 12px'};
    border-radius: 4px;
    outline: none;
    border-width: 0;
    background-color: ${({ redButton, theme }) => {
      if (redButton) {
        return theme.colors.red;
      }
      return theme.colors.primary.main;
    }};
    color: ${({ redButton, theme }) => {
        return 'white';
      //return theme.colors.secondary.main;
    }};
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);

    &:hover {
      color: white;
      opacity: 0.75;
      cursor: pointer;
    }
    &:active {
      background-color: ${(props) => props.theme.colors.secondary.light};
      border-color: transparent;
    }
  }

  @media (max-width: ${({ theme }) => theme.bp.lg}px) {
    .chat-button {
      font-size: 12px;
    }

    .status-holder {
      .my-status-value {
        font-size: 13px;
        color: ${({ redButton }) => (!redButton ? 'green' : 'red')};
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.bp.sm}px) {
    .chat-button {
      font-size: 10px;
    }
  }
`;

export { ButtonStyled };
