import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import {
  StyledBox, StyledButton, SectionContainer, SectionTitle,
  StyledA, SectionContent, SpinnerWrapper, Spinner, CloseButton
} from './styled';

const MinimalDrawer = ({ isOpen, setIsOpen, summary, suggestion,
  i18n, isLoading, saveSuggestion, sources = [] }) => {
  const handleClickButton = () => {
    saveSuggestion(suggestion);
    setIsOpen(false);
  };
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setIsOpen(open);
  };


  const renderLinks = (links) => {
    if (!Array.isArray(links) || links.length === 0) {
      return <p>{i18n.drawer.notAvailableSources}</p>;
    }
  
    return (
      <ul>
        {links.map((link, index) => (
          <li key={link}>
            <a href={link} target="_blank" rel="noopener noreferrer">
              {link}
            </a>
          </li>
        ))}
      </ul>
    );
  };
  const sourcesJsx = renderLinks(sources);
  return (
    <div>
      <Drawer anchor="bottom" open={isOpen} onClose={() => setIsOpen(false)}>
        <StyledBox>
        <CloseButton onClick={() => setIsOpen(false)} aria-label="Close Drawer">
            ✕
          </CloseButton>
          {/* Mostrar el loader si isLoading es true */}
          {isLoading ? (
            <SpinnerWrapper>
              <Spinner />
            </SpinnerWrapper>
          ) : (
            <>
              {/* Resumen de la conversación */}
              <SectionContainer>
                <SectionTitle>{i18n.drawer.summary}:</SectionTitle>
                <SectionContent>
                  {summary || i18n.drawer.notAvailableSummary}
                </SectionContent>
              </SectionContainer>

              {/* Respuesta sugerida */}
              <SectionContainer>
                <SectionTitle>{i18n.drawer.suggestedReply}:</SectionTitle>
                <SectionContent>
                  {suggestion || i18n.drawer.notAvailableSuggestion}
                </SectionContent>
                
              </SectionContainer>
              <SectionContainer>
                <SectionTitle>{i18n.drawer.sources}:</SectionTitle>
                <SectionContent>
                  {sourcesJsx}
                </SectionContent>
                
              </SectionContainer>
              {/* Botón de envío */}
              <StyledButton onClick={handleClickButton} disabled={!suggestion}>
                {i18n.drawer.useSuggestion}
              </StyledButton>
            </>
          )}
        </StyledBox>
      </Drawer>
    </div>
  );
};

MinimalDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  summary: PropTypes.string,
  suggestion: PropTypes.string,
  i18n: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  saveSuggestion: PropTypes.func,
  sources: PropTypes.array,
};

MinimalDrawer.defaultProps = {
  isLoading: false,
};

export default MinimalDrawer;
