import * as api from '../api';

class ChatGenService {
  askForSuggestion = async (
    convId,
    lastMessageId,
    userQuestion,
    agentId
  ) => {
    const agentType = 'helper';
    const channel = 'whatsapp';
    try {
      const suggestionsResp = await api.askForSuggestion({ 
        convId,
        lastMessageId,
        channel,
        userQuestion,
        agentType,
        agentId,
      });
      if (suggestionsResp?.responses?.length) {
        console.log('suggestion received', suggestionsResp?.responses);
        let suggestion = suggestionsResp?.responses.find((el) => el?.body)
        let sources = suggestionsResp?.responses.find((el) => el?.sources)
        return { suggestion: suggestion?.body, sources: sources?.sources };
      }
    } catch (error) {
      throw new Error(error);
    }
  };
/*   getChatGenAgent = async () => {
    try {
      const agent = await api.getChatGenAgent();
      console.log('agent received', agent);
      if (agent) {
        return agent;
      }
    } catch (error) {
      throw new Error(error);
    }
  }; */
  getIntegrationIagen = async () => {
    try {
      const integration = await api.getIntegrationChatGen();
      console.log('integrationIaGen', integration)
      return integration;
    } catch (error) {
      throw new Error(error);
    }
  };
}

const instance = new ChatGenService();

export default instance;
