import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Button,
  TextField,
} from "@mui/material";
import PropTypes from 'prop-types';
import { TableWrapper, ActionBar, Loader } from "./styled";
import { ChatCell } from "..";
import { formatDate } from "../../utils/utils";
import ModalSticky from "../modal-sticky/ModalSticky";
import { AgentsService } from "../../../services";
import { ActionButton, Spinner } from "../../atoms";
import AutocompleteSelect from "./Autocomplete";

const TableComponent = ({ agents, i18n, fetchMoreData, rows, setRows, findData }) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [showStickyModal, setShowStickyModal] = useState(false);
  const [modeStickyModal, setModeStickyModal] = useState('add'); // other mode delete
  const [selectedAgent, setSelectedAgent] = useState(null);
  const scrollRef = useRef();
  const selectAgent = (agent) => {
    setSelectedAgent(agent);
  }
  const bodyAddSticky = (
  <AutocompleteSelect
    options={agents}
    selectOption={selectAgent}
    optionSelected={selectedAgent}
    placeholder={i18n.stickyModal.search}
  />);
  const onEdit = (selRows) => {
    setSelectedRows(selRows);
    setModeStickyModal('add');
    setShowStickyModal(true);
  };
  const onReset = (selRows) => {
    setSelectedRows(selRows);
    setModeStickyModal('delete');
    setShowStickyModal(true);
  }
  const loadInitialData = async () => {
    setIsLoading(true);
    const initialData = await fetchMoreData(null);
    setRows(initialData);
    setIsLoading(false);
  };
  const cleanStatus = () => {
    setShowStickyModal(false);
    setSelectedRows([]);
    setSelectedAgent(null);
    if (searchQuery) {
      setSearchQuery('');
    } else {
      loadInitialData();
    }
  };
  const findContactIdConv = (convId) => {
    const conv = rows?.find((el) => el?.id === convId);
    return conv && conv.contactId;
  };
  const assignConvsToAgent = async (selRows, agent) => {
    try {

      const requests =  await selRows.map(row => AgentsService.addAgentToConv(findContactIdConv(row), agent?.agentId).then(response => {
        if (!response.ok) {
          throw new Error(`Error en la petición: ${response.status} - ${response.statusText}`);
        }
        return response;
      }));

      const results = await Promise.all(requests);
      return results;
    } catch (error) {
      console.error("Error al procesar las peticiones:", error);
      throw error;
    }
  }
  const freeConvsFromAgent = async (selRows) => {
    try {
      const requests =  await selRows.map(row => AgentsService.addAgentToConv(findContactIdConv(row), null).then(response => {
        if (!response.ok) {
          throw new Error(`Error en la petición: ${response.status} - ${response.statusText}`);
        }
        return response; 
      }));
      const results = await Promise.all(requests);
      return results;
    } catch (error) {
      console.error("Error al procesar las peticiones:", error);
      throw error;
    }
  };

  const onAcceptTransfer = async () => {
    await assignConvsToAgent(selectedRows, selectedAgent);
    cleanStatus();

  }
  const onAcceptFree = async () => {
    await freeConvsFromAgent(selectedRows);
    cleanStatus();

  }
  const onCancel = () => {
    cleanStatus();
  }

  const handleChangeQuery = async () => {
    const newData = await findData(searchQuery);
    console.log('prueba newData', newData);
  };

 
  useEffect(() => {
    handleChangeQuery();
  }, [searchQuery])
  useEffect(() => {
    loadInitialData();
  }, [fetchMoreData]);

  const loadMoreData = useCallback(async () => {
    if (isLoading || !hasMore) return;
    setIsLoading(true);
    const lastId = rows[rows?.length - 1]?.id || null;
    const newData = await fetchMoreData(lastId);
    if (newData.length === 0) setHasMore(false);
    if (newData.length === 1 && newData[0].id === lastId) {
      setHasMore(false);
      setIsLoading(false);
      return;
    }
    setRows((prevRows) => [...prevRows, ...newData]);
    setIsLoading(false);
  }, [isLoading, hasMore, rows, fetchMoreData]);

  const handleScroll = useCallback(() => {
    if (!scrollRef.current || isLoading || !hasMore) return;
    const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
    if (scrollHeight - scrollTop <= clientHeight + 50) {
      loadMoreData();
    }
  }, [isLoading, hasMore, loadMoreData]);

  useEffect(() => {
    const container = scrollRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, [handleScroll]);

  const handleSelectRow = (id) => {
    setSelectedRows((prev) =>
      prev.includes(id) ? prev?.filter((rowId) => rowId !== id) : [...prev, id]
    );
  };
  const handleSelectAll = (event) => {
    setSelectedRows(event.target.checked ? rows?.map((row) => row.id) : []);
  };

  const isAgentIdNull = (agentId) => agentId == null;

  const getAgentfromId = (agentId) => {
    if (isAgentIdNull(agentId)) return "";
    const agentFound = agents?.find((el) => el.agentId === agentId);
    return agentFound ? agentFound.name : `ID: ${agentId}`;
  };


  const isContactId = (conv) =>
    conv &&
    conv.contactId &&
    (conv.channel === "whatsapp" || conv.channel === "cloudapi");

  const getDateLastInputMessage = (conv) => {
    const locale = navigator.language;
    return conv.lastInputMessage ? formatDate(conv.lastInputMessage, locale) : "";
  };

  const getChatLabel = (conv) =>
    isContactId(conv) ? `+${conv.contactId}` : `#${conv?.contactId}`;
  return (
    <TableWrapper>
      <ModalSticky
        show={showStickyModal}
        mode={modeStickyModal}
        title={modeStickyModal === 'add' ? i18n.stickyModal.titleAdd : i18n.stickyModal.titleDelete}
        body={modeStickyModal === 'add' ? bodyAddSticky : i18n.stickyModal.bodyDeleteAgent}
        actionLabel={i18n.stickyModal.accept}
        onModalDoAction={modeStickyModal === 'add' ? onAcceptTransfer : onAcceptFree }
        cancelLabel={i18n.buttons.cancel}
        disabledSubmit={modeStickyModal === 'add' && !selectedAgent}
        onModalCancel={() => onCancel()}
      />
      <ActionBar>
        <ActionButton
          onClick={() => onEdit(selectedRows)}
          disabled={selectedRows?.length === 0}
          padding={12}
          label={
            i18n.stickyTable.assignAgent
          }
        />
        {/* <Button
          variant="contained"
          color="secondary"
          onClick={() => onReset(selectedRows)}
          disabled={selectedRows?.length === 0}
        >
          {i18n.stickyTable.free}
        </Button> */}
        <TextField
          variant="outlined"
          size="small"
          placeholder={i18n.stickyTable.searchDots}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </ActionBar>
      <TableContainer ref={scrollRef} style={{ maxHeight: "70vh", overflowY: "auto" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={
                    selectedRows?.length > 0 &&
                    selectedRows?.length < rows?.length
                  }
                  checked={selectedRows?.length === rows?.length}
                  onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell>{i18n.stickyTable.contact}</TableCell>
              <TableCell>{i18n.stickyTable.agent}</TableCell>
              <TableCell>{i18n.stickyTable.actions}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row) => {
              const chatLabel = getChatLabel(row);
              const dateLastInputMessage = getDateLastInputMessage(row);
              const agentIdNull = isAgentIdNull(row.agentId);
              return (
                <TableRow key={row.id} selected={selectedRows.includes(row.id)}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedRows.includes(row.id)}
                      onChange={() => handleSelectRow(row.id)}
                    />
                  </TableCell>
                  <TableCell sx={{ paddingLeft: 0 }}>
                    <ChatCell
                      externalUrl={row.externalUrl}
                      externalId={row.externalId}
                      selected={false}
                      onSelectChat={() => {}}
                      chatLabel={chatLabel}
                      key={row.id}
                      chatId={row.id}
                      profileName={row.profileName}
                      dateLastInputMessage={dateLastInputMessage}
                      convNum={row.id}
                      channel={row.channel}
                      newMessages={0}
                      showTransferIcon={false}
                      tableSticky
                    />
                  </TableCell>
                  <TableCell>{getAgentfromId(row.agentId)}</TableCell>
                  <TableCell>
                    {/* <ActionButton
                      onClick={agentIdNull ? () => onEdit([row.id]) : () => onReset(selectedRows)}
                      disabled={false}
                      isRed={!agentIdNull}
                      label={
                        agentIdNull
                          ? i18n.stickyTable.assignAgent
                          : i18n.stickyTable.free
                      }
                    /> */}
                    <ActionButton
                      onClick={() => onEdit([row.id])}
                      disabled={false}
                      label={
                        i18n.stickyTable.assignAgent
                      }
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
        {isLoading && <Loader>
          <Spinner size={40} />
          <p className="loading-text">{i18n.auth.loading}</p>
        </Loader>}
    </TableWrapper>
  );
};
TableComponent.propTypes = {
  agents: PropTypes.array,
  i18n: PropTypes.object,
  fetchMoreData: PropTypes.func,
  rows: PropTypes.array,
  setRows: PropTypes.func,
  findData: PropTypes.func, 
};

export default TableComponent;
