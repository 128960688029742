import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Switch, Redirect, NavLink, useHistory } from 'react-router-dom';
import SupervisorStyled from './styled';
import SocketProvider from '../../../sockets/provider';
import images from '../../../assets/images';
import { ReactComponent as LogoutIcon } from '../../../assets/images/logout.svg';
import { ReactComponent as MenuIcon } from '../../../assets/images/menu.svg';
import { StatisticPanel, AgentsPanel, AgentDetailPanel, ConversationDetailPanel, ConvsPanel } from '../../organisms';
import { AuthService } from '../../../services';
import Applications from '../applications';
import Paths from '../../../config/routes';
import { SelectApplication, Overlay, IconButton } from '../../atoms';
import useWhenComponentBlur from '../../molecules/send-message-input/useWhenComponentBlur';

const Supervisor = ({ i18n }) => (
  <SocketProvider>
    <SupervisorStyled>
      <SupervisorHeader i18n={i18n} text={i18n.supervisor.headerText} />
      <SupervisorMain i18n={i18n} />
    </SupervisorStyled>
  </SocketProvider>
);

Supervisor.propTypes = {
  i18n: PropTypes.object,
};

const SupervisorHeader = ({ i18n, text }) => {
  const { applicationName, applicationImage } = AuthService.getApplicationInfo();
  const history = useHistory();
  const [showMenu, setShowMenu] = useState(false);
  const overlayRef = useRef(null);

  useWhenComponentBlur(() => {
    setShowMenu(false);
  }, overlayRef);

  const logout = () => {
    AuthService.logout();
    history.replace(Paths.LOGIN);
  };

  return (
    <div className="supervisor-header">
      <img className="supervisor-header--logo" src={images.logo} alt="logo" />
      <p className="supervisor-header--text">{text}</p>
      <div ref={overlayRef}>
        <IconButton onClick={() => setShowMenu((current) => !current)} type="button">
          <MenuIcon className="menu-icon" />
        </IconButton>
        <Overlay className="chat-list__settings__menu" show={showMenu}>
          <div className="actions-wrapper">
            <p className="logout" onClick={logout}>
              <LogoutIcon className="logout-icon" />
              {i18n.chats.logout}
            </p>
            <div className="select-app-wrapper">
              <SelectApplication projectName={applicationName} projectImage={applicationImage} />
            </div>
          </div>
        </Overlay>
      </div>
    </div>
  );
};

SupervisorHeader.propTypes = {
  text: PropTypes.string,
  i18n: PropTypes.object,
};

const SupervisorMain = ({ i18n }) => {
  return (
    <>
      <div className="supervisor-main">
        <Router>
          <div className="tabs-container">
            <div className="tab-list">
              <NavLink to={Paths.SUPERVISOR_AGENTS} className="tab-list-item" activeClassName="tab-list-active">
                {i18n.supervisor.agentsTab}
              </NavLink>
              <NavLink to={Paths.SUPERVISOR_STATISTICS} className="tab-list-item" activeClassName="tab-list-active">
                {i18n.supervisor.statsTab}
              </NavLink>
              <NavLink to={Paths.SUPERVISOR_STICKY} className="tab-list-item" activeClassName="tab-list-active">
                {i18n.supervisor.convsTab}
              </NavLink>
            </div>
            <div className="tab-content">
              <Switch>
                {/** ROUTE ORDER MATTERS */}
                <Route path={Paths.SUPERVISOR_CONVERSATION_DETAIL} component={() => <ConversationDetailPanel />} />
                <Route path={Paths.SUPERVISOR_AGENT_DETAIL} component={() => <AgentDetailPanel />} />
                <Route path={Paths.SUPERVISOR_AGENTS} component={() => <AgentsPanel />} />
                <Route path={Paths.SUPERVISOR_STATISTICS} component={() => <StatisticPanel />} />
                <Route path={Paths.SUPERVISOR_STICKY} component={() => <ConvsPanel />} />
                <Route exact path={Paths.APPLICATIONS} component={Applications} />
                <Route path="*" render={() => <Redirect to={Paths.SUPERVISOR_AGENTS} />} />
              </Switch>
            </div>
          </div>
        </Router>
      </div>
    </>
  );
};

SupervisorMain.propTypes = {
  i18n: PropTypes.object,
};

export default Supervisor;
