import React from 'react';
import PropTypes from 'prop-types';
import { withI18n } from '../../../hocs';
import { ButtonStyled } from './styled';
import { AGENT_STATUS } from '../../../models';

const ActionButton = ({ onClick, label, isRed, padding }) => {
 /*  const status = agentStatus === AGENT_STATUS.AVAILABLE ? i18n.chats.available : i18n.chats.unavailable;
 */
console.log('padding', padding);
  return (
    <ButtonStyled redButton={isRed} padding={padding}>
      <div className="button-holder">
        <button onClick={onClick} className="chat-button ts ts-quick" type="button">
          {label}
        </button>
      </div>
    </ButtonStyled>
  );
};

ActionButton.propTypes = {
  onClick: PropTypes.func,
  padding: PropTypes.number,
  label: PropTypes.string,
  isRed: PropTypes.bool,
};

export default withI18n(ActionButton);
