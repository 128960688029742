/* eslint-disable react/no-unknown-property */
import React from 'react';
import PropTypes from 'prop-types';

function ImageFilledIcon({ className }) {
  return <svg className={className} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M21 19V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zM8.5 13.5l2.5 3.01L14.5 12l4.5 6H5l3.5-4.5z" />
  </svg>
}

ImageFilledIcon.propTypes = {
  className: PropTypes.string,
};

function CameraFilledIcon({ className }) {
  return <svg className={className} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4z" />
  </svg>
}

CameraFilledIcon.propTypes = {
  className: PropTypes.string,
};

function VolumeUpFilledIcon({ className }) {
  return <svg className={className} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
    <path d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
}

VolumeUpFilledIcon.propTypes = {
  className: PropTypes.string,
};

function DocumentFilledIcon({ className }) {
  return <svg className={className} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
    <path d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
}

DocumentFilledIcon.propTypes = {
  className: PropTypes.string,
};

function KeyboardDownArrowIcon({ className }) {
  return <svg className={className} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
    <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
    <path d="M0 0h24v24H0V0z" fill="none" />
  </svg>
}

KeyboardDownArrowIcon.propTypes = {
  className: PropTypes.string,
};

function UserIcon({ fill }) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 24 24">
    <path fill="none" d="M0 0h24v24H0V0z" />
    <path
      fill={fill}
      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"
    />
  </svg>
}

UserIcon.propTypes = {
  fill: PropTypes.string,
};

function TransferIcon({ className, fill }) {
  return <svg className={className} width="32" height="32" viewBox="0 0 24 24">
    <path
      fill={fill}
      d="M19,21V19H15V17H19V15L22,18L19,21M10,4A4,4 0 0,1 14,8A4,4 0 0,1 10,12A4,4 0 0,1 6,8A4,4 0 0,1 10,4M10,14C11.15,14 12.25,14.12 13.24,14.34C12.46,15.35 12,16.62 12,18C12,18.7 12.12,19.37 12.34,20H2V18C2,15.79 5.58,14 10,14Z"
    />
  </svg>
}

TransferIcon.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string,
};

function SendIcon({ className }) {
  return <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
}

SendIcon.propTypes = {
  className: PropTypes.string,
};

function AttachIcon({ className }) {
  return <svg className={className} viewBox="0 0 24 24">
    <path d="M16.5 6v11.5c0 2.21-1.79 4-4 4s-4-1.79-4-4V5c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5v10.5c0 .55-.45 1-1 1s-1-.45-1-1V6H10v9.5c0 1.38 1.12 2.5 2.5 2.5s2.5-1.12 2.5-2.5V5c0-2.21-1.79-4-4-4S7 2.79 7 5v12.5c0 3.04 2.46 5.5 5.5 5.5s5.5-2.46 5.5-5.5V6h-1.5z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
}

AttachIcon.propTypes = {
  className: PropTypes.string,
};

function CloudUploadIcon({ className }) {
  return <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z" />
  </svg>
}

CloudUploadIcon.propTypes = {
  className: PropTypes.string,
};

function AlertIcon({ className }) {
  return <svg className={className} focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
    <path fill="none" d="M0 0h24v24H0z" />
    <path fill="#ED5656" d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" />
  </svg>
}

AlertIcon.propTypes = {
  className: PropTypes.string,
};
function AiIcon({ className }) {

  return (
    <svg 
      className={className} 
      focusable="false" 
      viewBox="0 0 19 19" 
      aria-hidden="true" 
      role="presentation" 
      title="Web"
    >
      <defs>
        <path id="a" d="M0 0h18v17.86H0z"/>
        <path id="c" d="M0 17.86h18V0H0z"/>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
                          <mask id="b" fill="#A6A8A8">
            <use xlinkHref="#a"/>
          </mask>
          <path 
            fill="currentColor" 
            d="M13.38 13.4H4.62a.1.1 0 0 1-.1-.09V4.55c0-.06.05-.1.1-.1h8.76c.05 0 .1.04.1.1v8.76a.1.1 0 0 1-.1.1ZM18 6.67V5.49h-3.35v-.94c0-.7-.57-1.27-1.27-1.27h-.94V0h-1.17v3.28H9.59V0H8.4v3.28H6.73V0H5.56v3.28h-.94c-.7 0-1.27.57-1.27 1.27v.94H0v1.17h3.35v1.68H0v1.17h3.35v1.69H0v1.17h3.35v.94c0 .7.57 1.27 1.27 1.27h.94v3.28h1.17v-3.28h1.68v3.28H9.6v-3.28h1.68v3.28h1.17v-3.28h.94c.7 0 1.27-.57 1.27-1.27v-.94H18V11.2h-3.35V9.5H18V8.34h-3.35V6.66H18Z" 
            mask="url(#b)"
          />
        </g>
                          <mask id="d" fill="#A6A8A8">
          <use xlinkHref="#c"/>
        </mask>
        <path 
          fill="currentColor" 
          d="M5.78 11.7h1.38V6.16H5.78zM10.1 8.12l.07-.21.06.21.42 1.33H9.7l.42-1.33Zm-.77-1.96L7.56 11.7h1.4l.35-1.08h1.72l.35 1.08h1.4l-1.77-5.54H9.33Z" 
          mask="url(#d)"
        />
      </g>
    </svg>
  );
}

AiIcon.propTypes = {
  className: PropTypes.string,
};
function FileIcon({ className }) {
  return <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path fill="none" d="M0 0h24v24H0V0z" />
    <path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zM6 20V4h7v5h5v11H6z" />
  </svg>
}

FileIcon.propTypes = {
  className: PropTypes.string,
};

function DownloadIcon({ className }) {
  return <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path fill="none" d="M0 0h24v24H0V0z" />
    <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM19 18H6c-2.21 0-4-1.79-4-4 0-2.05 1.53-3.76 3.56-3.97l1.07-.11.5-.95C8.08 7.14 9.94 6 12 6c2.62 0 4.88 1.86 5.39 4.43l.3 1.5 1.53.11c1.56.1 2.78 1.41 2.78 2.96 0 1.65-1.35 3-3 3zm-5.55-8h-2.9v3H8l4 4 4-4h-2.55z" />
  </svg>
}

DownloadIcon.propTypes = {
  className: PropTypes.string,
};

function ErrorIcon({ className }) {
  return <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" />
  </svg>
}

ErrorIcon.propTypes = {
  className: PropTypes.string,
};

function PlayIcon({ className }) {
  return <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 13.5v-7c0-.41.47-.65.8-.4l4.67 3.5c.27.2.27.6 0 .8l-4.67 3.5c-.33.25-.8.01-.8-.4z" />
  </svg>
}

PlayIcon.propTypes = {
  className: PropTypes.string,
};

function BlockIcon({ className }) {
  return <svg className={className} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM4 12c0-4.42 3.58-8 8-8 1.85 0 3.55.63 4.9 1.69L5.69 16.9C4.63 15.55 4 13.85 4 12zm8 8c-1.85 0-3.55-.63-4.9-1.69L18.31 7.1C19.37 8.45 20 10.15 20 12c0 4.42-3.58 8-8 8z" />
  </svg>
}

BlockIcon.propTypes = {
  className: PropTypes.string,
};

function DeleteIcon({ className }) {
  return <svg className={className} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
    <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
}

DeleteIcon.propTypes = {
  className: PropTypes.string,
};

function DoneIcon({ className }) {
  return <svg className={className} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
  </svg>
}

DoneIcon.propTypes = {
  className: PropTypes.string,
};

function DoneAllIcon({ className }) {
  return <svg className={className} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M18 7l-1.41-1.41-6.34 6.34 1.41 1.41L18 7zm4.24-1.41L11.66 16.17 7.48 12l-1.41 1.41L11.66 19l12-12-1.42-1.41zM.41 13.41L6 19l1.41-1.41L1.83 12 .41 13.41z" />
  </svg>
}

DoneAllIcon.propTypes = {
  className: PropTypes.string,
};

function AlarmIcon({ className }) {
  return <svg className={className} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M22 5.72l-4.6-3.86-1.29 1.53 4.6 3.86L22 5.72zM7.88 3.39L6.6 1.86 2 5.71l1.29 1.53 4.59-3.85zM12.5 8H11v6l4.75 2.85.75-1.23-4-2.37V8zM12 4c-4.97 0-9 4.03-9 9s4.02 9 9 9c4.97 0 9-4.03 9-9s-4.03-9-9-9zm0 16c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z" />
  </svg>
}

AlarmIcon.propTypes = {
  className: PropTypes.string,
};

function AgentsSupStatsIcon({ className }) {
  return <svg className={className} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
    <path d="M3 5v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2H5c-1.11 0-2 .9-2 2zm12 4c0 1.66-1.34 3-3 3s-3-1.34-3-3 1.34-3 3-3 3 1.34 3 3zm-9 8c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1H6v-1z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
}

AgentsSupStatsIcon.propTypes = {
  className: PropTypes.string,
};

function ConversationsSupStatsIcon({ className }) {
  return <svg className={className} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M21 6h-2v9H6v2c0 .55.45 1 1 1h11l4 4V7c0-.55-.45-1-1-1zm-4 6V3c0-.55-.45-1-1-1H3c-.55 0-1 .45-1 1v14l4-4h10c.55 0 1-.45 1-1z" />
  </svg>
}

ConversationsSupStatsIcon.propTypes = {
  className: PropTypes.string,
};

function SettingsInputComponentIcon({ className }) {
  return <svg className={className} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M5 2c0-.55-.45-1-1-1s-1 .45-1 1v4H1v6h6V6H5V2zm4 14c0 1.3.84 2.4 2 2.82V23h2v-4.18c1.16-.41 2-1.51 2-2.82v-2H9v2zm-8 0c0 1.3.84 2.4 2 2.82V23h2v-4.18C6.16 18.4 7 17.3 7 16v-2H1v2zM21 6V2c0-.55-.45-1-1-1s-1 .45-1 1v4h-2v6h6V6h-2zm-8-4c0-.55-.45-1-1-1s-1 .45-1 1v4H9v6h6V6h-2V2zm4 14c0 1.3.84 2.4 2 2.82V23h2v-4.18c1.16-.41 2-1.51 2-2.82v-2h-6v2z" />
  </svg>
}

SettingsInputComponentIcon.propTypes = {
  className: PropTypes.string,
};

function UsersSupStatsIcon({ className }) {
  return <svg className={className} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M16.5 12c1.38 0 2.49-1.12 2.49-2.5S17.88 7 16.5 7C15.12 7 14 8.12 14 9.5s1.12 2.5 2.5 2.5zM9 11c1.66 0 2.99-1.34 2.99-3S10.66 5 9 5C7.34 5 6 6.34 6 8s1.34 3 3 3zm7.5 3c-1.83 0-5.5.92-5.5 2.75V19h11v-2.25c0-1.83-3.67-2.75-5.5-2.75zM9 13c-2.33 0-7 1.17-7 3.5V19h7v-2.25c0-.85.33-2.34 2.37-3.47C10.5 13.1 9.66 13 9 13z" />
  </svg>
}

UsersSupStatsIcon.propTypes = {
  className: PropTypes.string,
};

function AccountCancelIcon({ className }) {
  return <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M10 4A4 4 0 0 0 6 8A4 4 0 0 0 10 12A4 4 0 0 0 14 8A4 4 0 0 0 10 4M17.5 13C15 13 13 15 13 17.5C13 20 15 22 17.5 22C20 22 22 20 22 17.5C22 15 20 13 17.5 13M10 14C5.58 14 2 15.79 2 18V20H11.5A6.5 6.5 0 0 1 11 17.5A6.5 6.5 0 0 1 11.95 14.14C11.32 14.06 10.68 14 10 14M17.5 14.5C19.16 14.5 20.5 15.84 20.5 17.5C20.5 18.06 20.35 18.58 20.08 19L16 14.92C16.42 14.65 16.94 14.5 17.5 14.5M14.92 16L19 20.08C18.58 20.35 18.06 20.5 17.5 20.5C15.84 20.5 14.5 19.16 14.5 17.5C14.5 16.94 14.65 16.42 14.92 16Z" />
  </svg>
}

AccountCancelIcon.propTypes = {
  className: PropTypes.string,
};

function AccountCheckIcon({ className }) {
  return <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M21.1,12.5L22.5,13.91L15.97,20.5L12.5,17L13.9,15.59L15.97,17.67L21.1,12.5M10,17L13,20H3V18C3,15.79 6.58,14 11,14L12.89,14.11L10,17M11,4C13.21,4 15,5.79 15,8C15,10.21 13.21,12 11,12C8.79,12 7,10.21 7,8C7,5.79 8.79,4 11,4Z" />
  </svg>
}

AccountCheckIcon.propTypes = {
  className: PropTypes.string,
};

function TransferConvIcon({ className }) {
  return <svg className={className} width="24" height="24" viewBox="0 0 24 24">
    <path d="M19,21V19H15V17H19V15L22,18L19,21M10,4A4,4 0 0,1 14,8A4,4 0 0,1 10,12A4,4 0 0,1 6,8A4,4 0 0,1 10,4M10,14C11.15,14 12.25,14.12 13.24,14.34C12.46,15.35 12,16.62 12,18C12,18.7 12.12,19.37 12.34,20H2V18C2,15.79 5.58,14 10,14Z" />
  </svg>
}

TransferConvIcon.propTypes = {
  className: PropTypes.string,
};

function CloseConvIcon({ className }) {
  return <svg className={className} width="24" height="24" viewBox="0 0 24 24">
    <path d="M15,14C17.67,14 23,15.33 23,18V20H7V18C7,15.33 12.33,14 15,14M15,12A4,4 0 0,1 11,8A4,4 0 0,1 15,4A4,4 0 0,1 19,8A4,4 0 0,1 15,12M5,9.59L7.12,7.46L8.54,8.88L6.41,11L8.54,13.12L7.12,14.54L5,12.41L2.88,14.54L1.46,13.12L3.59,11L1.46,8.88L2.88,7.46L5,9.59Z" />
  </svg>
}

CloseConvIcon.propTypes = {
  className: PropTypes.string,
};

function VisibilityIcon({ className }) {
  return <svg className={className} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
  </svg>
}

VisibilityIcon.propTypes = {
  className: PropTypes.string,
};

function ArrowBackIcon({ className }) {
  return <svg className={className} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
    <path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
}

ArrowBackIcon.propTypes = {
  className: PropTypes.string,
};

function WebchatIcon({ className }) {
  return <svg className={className} style={{ width: 24, height: 24 }} focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation" title="Web">
    <path
      fill="currentColor"
      d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-5 14H4v-4h11v4zm0-5H4V9h11v4zm5 5h-4V9h4v9z"
    />
  </svg>
}

WebchatIcon.propTypes = {
  className: PropTypes.string,
};

function WhatsappIcon({ className }) {
  return <svg className={className} height="24" viewBox="0 0 24 24" width="24">
    <path
      fill="currentColor"
      d="M12.04 2C6.58 2 2.13 6.45 2.13 11.91C2.13 13.66 2.59 15.36 3.45 16.86L2.05 22L7.3 20.62C8.75 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.95 17.38 21.95 11.92C21.95 9.27 20.92 6.78 19.05 4.91C17.18 3.03 14.69 2 12.04 2M12.05 3.67C14.25 3.67 16.31 4.53 17.87 6.09C19.42 7.65 20.28 9.72 20.28 11.92C20.28 16.46 16.58 20.15 12.04 20.15C10.56 20.15 9.11 19.76 7.85 19L7.55 18.83L4.43 19.65L5.26 16.61L5.06 16.29C4.24 15 3.8 13.47 3.8 11.91C3.81 7.37 7.5 3.67 12.05 3.67M8.53 7.33C8.37 7.33 8.1 7.39 7.87 7.64C7.65 7.89 7 8.5 7 9.71C7 10.93 7.89 12.1 8 12.27C8.14 12.44 9.76 14.94 12.25 16C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.68 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.04 14.27C16.97 14.17 16.81 14.11 16.56 14C16.31 13.86 15.09 13.26 14.87 13.18C14.64 13.1 14.5 13.06 14.31 13.3C14.15 13.55 13.67 14.11 13.53 14.27C13.38 14.44 13.24 14.46 13 14.34C12.74 14.21 11.94 13.95 11 13.11C10.26 12.45 9.77 11.64 9.62 11.39C9.5 11.15 9.61 11 9.73 10.89C9.84 10.78 10 10.6 10.1 10.45C10.23 10.31 10.27 10.2 10.35 10.04C10.43 9.87 10.39 9.73 10.33 9.61C10.27 9.5 9.77 8.26 9.56 7.77C9.36 7.29 9.16 7.35 9 7.34C8.86 7.34 8.7 7.33 8.53 7.33Z"
    />
  </svg>
}

WhatsappIcon.propTypes = {
  className: PropTypes.string,
};

function EmojiIcon({ className }) {
  return <svg className={className} width="22px" height="22px" viewBox="0 0 22 22">
    <defs/>
    <g stroke="none" fill="none">
        <g transform="translate(1.000000, 1.000000)" fill="currentColor" stroke="#757575">
            <path d="M12.776618,7.18580376 C12.776618,6.7473904 13.1356994,6.38830898 13.5741127,6.38830898 C14.0125261,6.38830898 14.3716493,6.7473904 14.3716493,7.18580376 C14.3716493,7.62421712 14.0125261,7.98329854 13.5741127,7.98329854 C13.1356994,7.98329854 12.776618,7.62421712 12.776618,7.18580376 Z M6.38830898,7.98329854 C6.82672234,7.98329854 7.18580376,7.62421712 7.18580376,7.18580376 C7.18580376,6.7473904 6.82672234,6.38830898 6.38830898,6.38830898 C5.94989562,6.38830898 5.5908142,6.7473904 5.5908142,7.18580376 C5.5908142,7.62421712 5.94989562,7.98329854 6.38830898,7.98329854 Z M5.5908142,12.776618 L5.5908142,13.5741127 C5.5908142,13.5741127 7.18580376,15.1691023 9.98329854,15.1691023 C12.7807933,15.1691023 14.3757829,13.5741127 14.3757829,13.5741127 L14.3757829,12.776618 C14.3757829,12.776618 12.7807933,14.3716075 9.98329854,14.3716075 C7.18580376,14.3716075 5.5908142,12.776618 5.5908142,12.776618 Z M19.9665971,9.98329854 C19.9665971,15.4947808 15.4947808,19.9665971 9.98329854,19.9665971 C4.47181628,19.9665971 0,15.4947808 0,9.98329854 C0,4.47181628 4.47181628,0 9.98329854,0 C15.4906054,0 19.9665971,4.47181628 19.9665971,9.98329854 Z M19.1649269,9.98329854 C19.1649269,4.87265136 15.0939457,0.801670146 9.98329854,0.801670146 C4.87265136,0.801670146 0.801670146,4.87265136 0.801670146,9.98329854 C0.801670146,15.0939457 4.87265136,19.1649269 9.98329854,19.1649269 C15.0939457,19.1649269 19.1649269,15.0939457 19.1649269,9.98329854 Z" id="Shape"/>
        </g>
    </g>
  </svg>
}

EmojiIcon.propTypes = {
  className: PropTypes.string,
};

function MessengerIcon({ className }) {
  return <svg className={className} style={{ width: 24, height: 24 }} version="1.1" viewBox="0 0 224 226">
    <path fill="currentColor" d="m41.255 185.52v40.2l37.589-21.37c10.478 3.02 21.616 4.65 33.156 4.65 61.86 0 112-46.79 112-104.5 0-57.714-50.14-104.5-112-104.5-61.856 0-112 46.786-112 104.5 0 32.68 16.078 61.86 41.255 81.02z"/>
    <path fill="#fff" d="m100.04 75.878l-60.401 63.952 54.97-30.16 28.721 30.16 60.06-63.952-54.36 29.632-28.99-29.632z"/>
  </svg>
}


MessengerIcon.propTypes = {
  className: PropTypes.string,
};

function PapersIcon({ className }) {
  return <svg className={className} style={{ width: 22, height: 22 }} version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 111.26 122.88" >
    <g>
      <path d="M21.78,62.8c-1.61,0-2.91-1.43-2.91-3.19c0-1.76,1.3-3.19,2.91-3.19h24.91c1.61,0,2.91,1.43,2.91,3.19 c0,1.76-1.3,3.19-2.91,3.19H21.78L21.78,62.8z M93.37,19.34h10.43c2.05,0,3.92,0.84,5.27,2.19c1.35,1.35,2.19,3.22,2.19,5.27v88.62 c0,2.06-0.84,3.92-2.19,5.27c-1.35,1.35-3.22,2.19-5.27,2.19H25.35c-2.05,0-3.92-0.84-5.27-2.19c-1.35-1.35-2.19-3.21-2.19-5.27 v-11.88H7.46c-2.05,0-3.92-0.84-5.27-2.19C0.84,100,0,98.13,0,96.08V7.46C0,5.4,0.84,3.54,2.19,2.19C3.54,0.84,5.4,0,7.46,0h78.45 c2.05,0,3.92,0.84,5.27,2.19c1.35,1.35,2.19,3.22,2.19,5.27V19.34L93.37,19.34z M24.28,103.54v11.88c0,0.29,0.12,0.56,0.32,0.75 c0.2,0.2,0.46,0.32,0.75,0.32h78.45c0.29,0,0.56-0.12,0.75-0.32c0.2-0.2,0.32-0.46,0.32-0.75V26.8c0-0.29-0.12-0.56-0.32-0.75 c-0.2-0.2-0.46-0.32-0.75-0.32H93.37v70.34c0,2.05-0.84,3.92-2.19,5.27c-1.35,1.35-3.22,2.19-5.27,2.19H24.28L24.28,103.54z M85.91,6.39H7.46c-0.29,0-0.56,0.12-0.75,0.32c-0.2,0.2-0.32,0.46-0.32,0.75v88.62c0,0.29,0.12,0.56,0.32,0.75 c0.2,0.2,0.46,0.32,0.75,0.32h78.45c0.29,0,0.56-0.12,0.75-0.32c0.2-0.2,0.32-0.46,0.32-0.75V7.46c0-0.29-0.12-0.56-0.32-0.75 C86.47,6.51,86.2,6.39,85.91,6.39L85.91,6.39z M21.78,28.64c-1.61,0-2.91-1.43-2.91-3.19c0-1.76,1.3-3.19,2.91-3.19h49.81 c1.61,0,2.91,1.43,2.91,3.19c0,1.76-1.3,3.19-2.91,3.19H21.78L21.78,28.64z M21.78,45.72c-1.61,0-2.91-1.43-2.91-3.19 c0-1.76,1.3-3.19,2.91-3.19h49.81c1.61,0,2.91,1.43,2.91,3.19c0,1.76-1.3,3.19-2.91,3.19H21.78L21.78,45.72z" />
    </g>
  </svg>
}

PapersIcon.propTypes = {
  className: PropTypes.string,
};

function AddIcon({ className }) {
  return <svg className={className} style={{ width: 24, height: 24 }} focusable="false" viewBox="0 0 24 24" aria-hidden="true">
    <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
  </svg>
}

AddIcon.propTypes = {
  className: PropTypes.string,
};

function BanUserIcon({ className }) {
  return <svg className={className} style={{ width: 24, height: 24 }} viewBox="0 0 24 24">
    <path d="M10 4A4 4 0 0 0 6 8A4 4 0 0 0 10 12A4 4 0 0 0 14 8A4 4 0 0 0 10 4M17.5 13C15 13 13 15 13 17.5C13 20 15 22 17.5 22C20 22 22 20 22 17.5C22 15 20 13 17.5 13M10 14C5.58 14 2 15.79 2 18V20H11.5A6.5 6.5 0 0 1 11 17.5A6.5 6.5 0 0 1 11.95 14.14C11.32 14.06 10.68 14 10 14M17.5 14.5C19.16 14.5 20.5 15.84 20.5 17.5C20.5 18.06 20.35 18.58 20.08 19L16 14.92C16.42 14.65 16.94 14.5 17.5 14.5M14.92 16L19 20.08C18.58 20.35 18.06 20.5 17.5 20.5C15.84 20.5 14.5 19.16 14.5 17.5C14.5 16.94 14.65 16.42 14.92 16Z" />
  </svg>
}

BanUserIcon.propTypes = {
  className: PropTypes.string,
};

function SettingsIcon({ className }) {
  return <svg className={className} style={{ width: 24, height: 24 }} viewBox="0 0 24 24">
    <path d="M12,15.5A3.5,3.5 0 0,1 8.5,12A3.5,3.5 0 0,1 12,8.5A3.5,3.5 0 0,1 15.5,12A3.5,3.5 0 0,1 12,15.5M19.43,12.97C19.47,12.65 19.5,12.33 19.5,12C19.5,11.67 19.47,11.34 19.43,11L21.54,9.37C21.73,9.22 21.78,8.95 21.66,8.73L19.66,5.27C19.54,5.05 19.27,4.96 19.05,5.05L16.56,6.05C16.04,5.66 15.5,5.32 14.87,5.07L14.5,2.42C14.46,2.18 14.25,2 14,2H10C9.75,2 9.54,2.18 9.5,2.42L9.13,5.07C8.5,5.32 7.96,5.66 7.44,6.05L4.95,5.05C4.73,4.96 4.46,5.05 4.34,5.27L2.34,8.73C2.21,8.95 2.27,9.22 2.46,9.37L4.57,11C4.53,11.34 4.5,11.67 4.5,12C4.5,12.33 4.53,12.65 4.57,12.97L2.46,14.63C2.27,14.78 2.21,15.05 2.34,15.27L4.34,18.73C4.46,18.95 4.73,19.03 4.95,18.95L7.44,17.94C7.96,18.34 8.5,18.68 9.13,18.93L9.5,21.58C9.54,21.82 9.75,22 10,22H14C14.25,22 14.46,21.82 14.5,21.58L14.87,18.93C15.5,18.67 16.04,18.34 16.56,17.94L19.05,18.95C19.27,19.03 19.54,18.95 19.66,18.73L21.66,15.27C21.78,15.05 21.73,14.78 21.54,14.63L19.43,12.97Z" />
  </svg>
}

SettingsIcon.propTypes = {
  className: PropTypes.string,
};

function UnbanUserIcon({ className }) {
  return <svg className={className} style={{ width: 24, height: 24 }} viewBox="0 0 24 24">
    <path d="M21.1,12.5L22.5,13.91L15.97,20.5L12.5,17L13.9,15.59L15.97,17.67L21.1,12.5M10,17L13,20H3V18C3,15.79 6.58,14 11,14L12.89,14.11L10,17M11,4A4,4 0 0,1 15,8A4,4 0 0,1 11,12A4,4 0 0,1 7,8A4,4 0 0,1 11,4Z" />
  </svg>
}

UnbanUserIcon.propTypes = {
  className: PropTypes.string,
};

function EmailIcon({ className }) {
  return <svg className={className} style={{ width: 24, height: 24 }} viewBox="0 0 24 24">
    <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4-8 5-8-5V6l8 5 8-5v2z"/>
  </svg>
}

EmailIcon.propTypes = {
  className: PropTypes.string,
};

function PhoneIcon({ className }) {
  return <svg className={className} style={{ width: 24, height: 24 }}  viewBox="0 0 24 24">
    <path d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z" />
  </svg>
}

PhoneIcon.propTypes = {
  className: PropTypes.string,
};

function LocationIcon({ className }) {
  return <svg className={className} style={{ width: 24, height: 24 }}  viewBox="0 0 24 24" >
    <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" />
  </svg>
}

LocationIcon.propTypes = {
  className: PropTypes.string,
};

function BaggageIcon({ className }) {
  return <svg className={className} style={{ width: 24, height: 24 }} viewBox="0 0 24 24">
    <path d="M17 6h-2V3c0-.55-.45-1-1-1h-4c-.55 0-1 .45-1 1v3H7c-1.1 0-2 .9-2 2v11c0 1.1.9 2 2 2 0 .55.45 1 1 1s1-.45 1-1h6c0 .55.45 1 1 1s1-.45 1-1c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zM9.5 18H8V9h1.5v9zm3.25 0h-1.5V9h1.5v9zm.75-12h-3V3.5h3V6zM16 18h-1.5V9H16v9z" />
  </svg>
}

BaggageIcon.propTypes = {
  className: PropTypes.string,
};

function BulletedListIcon({ className }) {
  return <svg className={className} style={{ width: 24, height: 24 }} viewBox="0 0 24 24">
    <path d="M4 10.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0-6c-.83 0-1.5.67-1.5 1.5S3.17 7.5 4 7.5 5.5 6.83 5.5 6 4.83 4.5 4 4.5zm0 12c-.83 0-1.5.68-1.5 1.5s.68 1.5 1.5 1.5 1.5-.68 1.5-1.5-.67-1.5-1.5-1.5zM7 19h14v-2H7v2zm0-6h14v-2H7v2zm0-8v2h14V5H7z"/>
  </svg>
}
BulletedListIcon.propTypes = {
  className: PropTypes.string,
};

function RotateLeftIcon({ className }) {
  return <svg className={className} style={{ width: 24, height: 24 }} viewBox="0 0 24 24">
    <path d="M6.758 8.758 5.344 7.344a8.048 8.048 0 0 0-1.841 2.859l1.873.701a6.048 6.048 0 0 1 1.382-2.146zM19 12.999a7.935 7.935 0 0 0-2.344-5.655A7.917 7.917 0 0 0 12 5.069V2L7 6l5 4V7.089a5.944 5.944 0 0 1 3.242 1.669A5.956 5.956 0 0 1 17 13v.002c0 .33-.033.655-.086.977-.007.043-.011.088-.019.131a6.053 6.053 0 0 1-1.138 2.536c-.16.209-.331.412-.516.597a5.954 5.954 0 0 1-.728.613 5.906 5.906 0 0 1-2.277 1.015c-.142.03-.285.05-.43.069-.062.009-.122.021-.184.027a6.104 6.104 0 0 1-1.898-.103L9.3 20.819a8.087 8.087 0 0 0 2.534.136c.069-.007.138-.021.207-.03.205-.026.409-.056.61-.098l.053-.009-.001-.005a7.877 7.877 0 0 0 2.136-.795l.001.001.028-.019a7.906 7.906 0 0 0 1.01-.67c.27-.209.532-.43.777-.675.248-.247.47-.513.681-.785.021-.028.049-.053.07-.081l-.006-.004a7.899 7.899 0 0 0 1.093-1.997l.008.003c.029-.078.05-.158.076-.237.037-.11.075-.221.107-.333.04-.14.073-.281.105-.423.022-.099.048-.195.066-.295.032-.171.056-.344.076-.516.01-.076.023-.15.03-.227.023-.249.037-.5.037-.753.002-.002.002-.004.002-.008zM6.197 16.597l-1.6 1.201a8.045 8.045 0 0 0 2.569 2.225l.961-1.754a6.018 6.018 0 0 1-1.93-1.672zM5 13c0-.145.005-.287.015-.429l-1.994-.143a7.977 7.977 0 0 0 .483 3.372l1.873-.701A5.975 5.975 0 0 1 5 13z"/>
  </svg>
}
RotateLeftIcon.propTypes = {
  className: PropTypes.string,
};

function RotateRightIcon({ className }) {
  return <svg className={className} style={{ width: 24, height: 24 }} viewBox="0 0 24 24">
    <path d="M16.242 17.242a6.04 6.04 0 0 1-1.37 1.027l.961 1.754a8.068 8.068 0 0 0 2.569-2.225l-1.6-1.201a5.938 5.938 0 0 1-.56.645zm1.743-4.671a5.975 5.975 0 0 1-.362 2.528l1.873.701a7.977 7.977 0 0 0 .483-3.371l-1.994.142zm1.512-2.368a8.048 8.048 0 0 0-1.841-2.859l-1.414 1.414a6.071 6.071 0 0 1 1.382 2.146l1.873-.701zm-8.128 8.763c-.047-.005-.094-.015-.141-.021a6.701 6.701 0 0 1-.468-.075 5.923 5.923 0 0 1-2.421-1.122 5.954 5.954 0 0 1-.583-.506 6.138 6.138 0 0 1-.516-.597 5.91 5.91 0 0 1-.891-1.634 6.086 6.086 0 0 1-.247-.902c-.008-.043-.012-.088-.019-.131A6.332 6.332 0 0 1 6 13.002V13c0-1.603.624-3.109 1.758-4.242A5.944 5.944 0 0 1 11 7.089V10l5-4-5-4v3.069a7.917 7.917 0 0 0-4.656 2.275A7.936 7.936 0 0 0 4 12.999v.009c0 .253.014.504.037.753.007.076.021.15.03.227.021.172.044.345.076.516.019.1.044.196.066.295.032.142.065.283.105.423.032.112.07.223.107.333.026.079.047.159.076.237l.008-.003A7.948 7.948 0 0 0 5.6 17.785l-.007.005c.021.028.049.053.07.081.211.272.433.538.681.785a8.236 8.236 0 0 0 .966.816c.265.192.537.372.821.529l.028.019.001-.001a7.877 7.877 0 0 0 2.136.795l-.001.005.053.009c.201.042.405.071.61.098.069.009.138.023.207.03a8.038 8.038 0 0 0 2.532-.137l-.424-1.955a6.11 6.11 0 0 1-1.904.102z"/>
  </svg>
}
RotateRightIcon.propTypes = {
  className: PropTypes.string,
};


function CloseIcon({ className }) {
  return <svg className={className} style={{ width: 24, height: 24 }} viewBox="0 0 24 24">
    <path d="M 4.9902344 3.9902344 A 1.0001 1.0001 0 0 0 4.2929688 5.7070312 L 10.585938 12 L 4.2929688 18.292969 A 1.0001 1.0001 0 1 0 5.7070312 19.707031 L 12 13.414062 L 18.292969 19.707031 A 1.0001 1.0001 0 1 0 19.707031 18.292969 L 13.414062 12 L 19.707031 5.7070312 A 1.0001 1.0001 0 0 0 18.980469 3.9902344 A 1.0001 1.0001 0 0 0 18.292969 4.2929688 L 12 10.585938 L 5.7070312 4.2929688 A 1.0001 1.0001 0 0 0 4.9902344 3.9902344 z" />
  </svg>
}

CloseIcon.propTypes = {
  className: PropTypes.string,
};

function VerticalDotsIcon({ className }) {
  return <svg className={className} style={{ width: 24, height: 24 }} viewBox="0 0 24 24">
  <circle cx="12" cy="5" r="2"></circle>
  <circle cx="12" cy="12" r="2"></circle>
  <circle cx="12" cy="19" r="2"></circle>
</svg>
}

VerticalDotsIcon.propTypes = {
  className: PropTypes.string,
};

export {
  ImageFilledIcon,
  CameraFilledIcon,
  VolumeUpFilledIcon,
  DocumentFilledIcon,
  KeyboardDownArrowIcon,
  UserIcon,
  TransferIcon,
  SendIcon,
  AttachIcon,
  CloudUploadIcon,
  AlertIcon,
  FileIcon,
  DownloadIcon,
  ErrorIcon,
  PlayIcon,
  BlockIcon,
  DeleteIcon,
  DoneIcon,
  DoneAllIcon,
  AlarmIcon,
  AgentsSupStatsIcon,
  ConversationsSupStatsIcon,
  SettingsInputComponentIcon,
  UsersSupStatsIcon,
  AccountCancelIcon,
  AccountCheckIcon,
  VisibilityIcon,
  TransferConvIcon,
  CloseConvIcon,
  ArrowBackIcon,
  WebchatIcon,
  WhatsappIcon,
  EmojiIcon,
  MessengerIcon,
  PapersIcon,
  AddIcon,
  BanUserIcon,
  SettingsIcon,
  UnbanUserIcon,
  PhoneIcon,
  EmailIcon,
  LocationIcon,
  BaggageIcon,
  BulletedListIcon,
  RotateRightIcon,
  RotateLeftIcon,
  CloseIcon,
  VerticalDotsIcon,
  AiIcon,
};
