import React from 'react';
import PropTypes from 'prop-types'
import Select from 'react-select';

const AutocompleteSelect = ({ options, selectOption, optionSelected, placeholder }) => {
  const handleChange = (selectedOption) => {
    selectOption(selectedOption);
  };

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: '500px',
    }),
    menu: (provided) => ({
      ...provided,
      width: '500px',
    }),
  };

  return (
    <Select
      options={options}
      isSearchable
      getOptionValue={(option) => option?.agentId?.toString() || ''}
      getOptionLabel={(option) => option?.name || 'unknown'}
      onChange={handleChange}
      value={optionSelected}
      placeholder={placeholder}
      styles={customStyles}
    />
  );
};
AutocompleteSelect.propTypes={
    options: PropTypes.array,
    selectOption: PropTypes.func.isRequired,
    optionSelected: PropTypes.object,
    placeholder: PropTypes.string,
}

export default AutocompleteSelect;
