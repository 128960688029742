import moment from 'moment';

export const formatDate = (dateString, locale) => {
  const now = new Date();
  const date = new Date(dateString);
  const diff = now - date;
  const oneDay = 24 * 60 * 60 * 1000;
  const oneMonth = 30 * oneDay;
  const oneYear = 365 * oneDay;
  if (diff < oneDay) {
    return moment(dateString).format(locale === 'es' ? 'HH:mm' : 'hh:mm A');
  }

  if (diff < 2 * oneDay) {
    return locale === 'es' ? 'Ayer' : 'Yesterday';
  }

  if (diff < oneMonth) {
    const days = Math.floor(diff / oneDay);
    return locale === 'es' ? `hace ${days} día${days > 1 ? 's' : ''}` : `${days} day${days > 1 ? 's' : ''} ago`;
  }

  if (diff < oneYear) {
    const months = Math.floor(diff / oneMonth);
    return locale === 'es' ? `hace ${months} mes${months > 1 ? 'es' : ''}` : `${months} month${months > 1 ? 's' : ''} ago`;
  }

  const years = Math.floor(diff / oneYear);
  return locale === 'es' ? `hace ${years} año${years > 1 ? 's' : ''}` : `${years} year${years > 1 ? 's' : ''} ago`;
}
