import styled from 'styled-components';

export const TableWrapper = styled.div`
  width: 100%;
  padding: 16px;
`;

export const ActionBar = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
  align-items: center;
`;

export const Loader = styled.div`

  display: flex;
  align-items: center;
  margin-top: 15px;
  .loading-text {
    font-size: 24px;
    margin-left: 15px;
    padding-bottom: 0;
    color: ${({ theme }) => theme.colors.secondary.darker};
  }

`;