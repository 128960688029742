/* eslint-disable consistent-return */
import React, { useState, useRef, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { Picker } from 'emoji-mart';
import { useMediaQuery } from 'react-responsive';
import _ from 'lodash';
import bp from '../../../config/breakpoints';

import { KEYS } from '../../../config/keys';
import { sendMessage } from '../../../sockets/emit';
import { useSocketContext } from '../../../sockets/context';
import { MSG_CONTENT_TYPES, MODAL_TYPE, MSG_SEND_TYPE } from '../../../models';
import MinimalDrawer from '../DrawerMenu';
import { withI18n } from '../../../hocs';
import {
  ImageFilledIcon,
  CameraFilledIcon,
  VolumeUpFilledIcon,
  DocumentFilledIcon,
  SendIcon,
  AttachIcon,
  IconButton,
  AddIcon,
  DeleteIcon,
  EmojiIcon,
  PapersIcon,
  VerticalDotsIcon,
  AiIcon,
} from '../../atoms';
import { getEnvironment } from '../../../api/utils';
import { SendInputStyled, DropdownMenu, MenuItem } from './styled';
import useWhenComponentBlur from './useWhenComponentBlur';
import { ContactsService, ChatGenService } from '../../../services';

// create pop up for attaching
const AttachMenuSelector = ({ i18n, onAttachEvent, onSelectMenu }) => {
  return <div className="selector ts ts-ease-out">
    <ul className="ts ts-ease-out">
      <li className="ts ts-ease-out">
        <IconButton
          onClick={() => {
            onSelectMenu();
            onAttachEvent(MODAL_TYPE.ATTACH.IMAGE);
          }}
          className="menu-button"
        >
          <ImageFilledIcon className="menu-button-icon ts" />
          {i18n.attachMenu.image}
        </IconButton>
      </li>
      <li className="ts ts-ease-out">
        <IconButton
          onClick={() => {
            onSelectMenu();
            onAttachEvent(MODAL_TYPE.ATTACH.VIDEO);
          }}
          className="menu-button"
        >
          <CameraFilledIcon className="menu-button-icon ts" />
          {i18n.attachMenu.video}
        </IconButton>
      </li>
      <li className="ts ts-ease-out">
        <IconButton
          onClick={() => {
            onSelectMenu();
            onAttachEvent(MODAL_TYPE.ATTACH.DOCUMENT);
          }}
          className="menu-button"
        >
          <DocumentFilledIcon className="menu-button-icon ts" />
          {i18n.attachMenu.document}
        </IconButton>
      </li>
      <li className="ts ts-ease-out">
        <IconButton
          onClick={() => {
            onSelectMenu();
            onAttachEvent(MODAL_TYPE.ATTACH.AUDIO);
          }}
          className="menu-button"
        >
          <VolumeUpFilledIcon className="menu-button-icon ts" />
          {i18n.attachMenu.audio}
        </IconButton>
      </li>
    </ul>
  </div>
}

AttachMenuSelector.propTypes = {
  i18n: PropTypes.object,
  onAttachEvent: PropTypes.func,
  onSelectMenu: PropTypes.func,
};

const TemplateSelector = forwardRef(({ 
  disabledTemplateSending, 
  i18n, 
  list, 
  title, 
  onTemplateSelected, 
  onAddTemplate, 
  onDeleteTemplate, 
  hideToolTip, 
  isAuditor, 
  sendTemplate, 
  setTemplateToPreview 
}, ref) => {
  const deleteTemplate = (e, fragmentId) => {
    e.preventDefault();
    e.stopPropagation();
    onDeleteTemplate(fragmentId);
  };

  const [selectedOption, setSelectedOption] = useState("all");
  let filteredList = list;
  if (list) {
    if (!isAuditor) {
      filteredList = list.filter((el) => (el && !el.onlyAuditor));
    } else if (selectedOption === 'onlyAuditor') {
      filteredList = list.filter(el => el && el.onlyAuditor) || [];
    }
  }

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const [actionsMenu, setActionsMenu] = useState({
    isOpen: false,
    top: 0,
    left: 0,
  });

  const handleToggleOptions = (event, templateId) => {
    event.stopPropagation();
    const { top, left, height } = event.currentTarget.getBoundingClientRect();
    setActionsMenu((prevMenu) => ({
      isOpen: prevMenu.isOpen === templateId ? false : templateId, // Open menu only for the clicked template
      top: top + height,
      left,
    }));
  };

  const handleClickOutside = (event) => {
    if (!event.target.closest('.fragment-element')) {
      setActionsMenu((prevMenu) => ({
        ...prevMenu,
        isOpen: false,
      }));
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [actionsMenu]);
  return (
    <section className="fragment-selector" ref={ref}>
      <header className="fragment-selector-header">
        <span className="fragment-selector-title">{title}</span>
        <IconButton
          toolTip={i18n.templateSelector.addTemplate}
          toolTipPosition="top"
          toolTipPositionOffset={5}
          onClick={onAddTemplate}
          className="add-fragment-button"
        >
          <AddIcon />
        </IconButton>
      </header>
      {isAuditor ? (
        <div>
          <form className='form-radios'>
            <p className="radio-title">{i18n.templateSelector.titleRadios}</p>
            <div className="flex-form-radio">
              <div className="radio-content">
                <label htmlFor="onlyAuditor" className="label-radio">
                  {i18n.templateSelector.auditor}
                </label>
                <input
                  type="radio"
                  id="onlyAuditor"
                  name="fav_language"
                  className="input-radio"
                  value="onlyAuditor"
                  checked={selectedOption === "onlyAuditor"}
                  onChange={handleRadioChange}
                />
              </div>
              <div className="radio-content">
                <label htmlFor="all" className="label-radio">
                  {i18n.templateSelector.all}
                </label>
                <input
                  type="radio"
                  id="all"
                  name="fav_language"
                  value="all"
                  className="input-radio"
                  checked={selectedOption === "all"}
                  onChange={handleRadioChange}
                />
              </div>
            </div>
            <hr />
          </form>
        </div>
      ) : (
        <div />
      )}
      <ul className="ts ts-ease-out">
        {filteredList &&
          filteredList.map((template) => {
            const isTemplate = template?.template?.contentType?.toLowerCase() === 'template';
            return (
              <li className="ts ts-ease-out fragment-element" key={`template-${template.id}`} onClick={template && template.onlyAuditor ? () => {} : () => onTemplateSelected(template.message)}>
                <p className="fragment-element-title">{template.title}</p>
                <p className="fragment-element-message">{template.message}</p>
                {isAuditor && template && template.onlyAuditor && template.template && (
                  <IconButton
                    toolTip={i18n.templateSelector.sendTemplate}
                    toolTipPosition="top"
                    toolTipPositionOffset={5}
                    disableToolTip={hideToolTip}
                    disabled={disabledTemplateSending}
                    onClick={disabledTemplateSending ? null : (e) => sendTemplate(template.template)}
                    className="delete-button"
                  >
                    <SendIcon className="send-icon ts" />
                  </IconButton>
                )}
                {!isTemplate && (
                  <IconButton
                    toolTip={i18n.templateSelector.deleteTemplate}
                    toolTipPosition="top"
                    toolTipPositionOffset={5}
                    disableToolTip={hideToolTip}
                    onClick={(e) => deleteTemplate(e, template.id)}
                    className="delete-button"
                  >
                    <DeleteIcon className="send-icon ts" />
                  </IconButton>
                )}
                {isTemplate && (
                  <>
                    <IconButton
                      toolTip={i18n.templateSelector.moreActions}
                      toolTipPosition="top"
                      toolTipPositionOffset={5}
                      disableToolTip={hideToolTip}
                      onClick={(e) => handleToggleOptions(e, template.id)} // Pass template id to differentiate
                      className="delete-button"
                    >
                      <VerticalDotsIcon className="send-icon ts" />
                    </IconButton>
                    <DropdownMenu
                      onClick={(e) => e.stopPropagation()}
                      isOpen={actionsMenu.isOpen === template.id} // Check if this menu should be open
                      top={actionsMenu.top}
                      left={actionsMenu.left}
                    >
                      <MenuItem onClick={(e) => deleteTemplate(e, template.id)}>{i18n.templateSelector.deleteTemplate}</MenuItem>
                      <MenuItem onClick={() => { setTemplateToPreview(template); }}>{i18n.templateSelector.viewTemplate}</MenuItem>
                    </DropdownMenu>
                  </>
                )}
              </li>
            );
          })}
      </ul>
    </section>
  );
});

TemplateSelector.propTypes = {
  i18n: PropTypes.object,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      message: PropTypes.string,
    })
  ),
  title: PropTypes.string,
  hideToolTip: PropTypes.bool,
  onTemplateSelected: PropTypes.func.isRequired,
  onAddTemplate: PropTypes.func.isRequired,
  onDeleteTemplate: PropTypes.func.isRequired,
  sendTemplate: PropTypes.func,
  isAuditor: PropTypes.bool,
  disabledTemplateSending: PropTypes.bool,
  setTemplateToPreview: PropTypes.func,
};

const SendMessageInput = ({
  i18n,
  templateList,
  onAttachEvent,
  onAddTemplate,
  onDeleteTemplate, 
  assignConvToMyself,
  sendTemplate,
  showOnlyButton,
  isAuditor,
  setGlobalState,
  conversations,
  setScrollAllowed,
  disabledTemplateSending,
  setTemplateToPreview,
}) => {
  const isMediumScreen = useMediaQuery({ query: `(max-width: ${bp.md}px)` });
  const inputRef = useRef(null);
  const divRef = useRef(null);
  const emojiPickerRef = useRef(null);
  const templateSelectorRef = useRef(null);
  const [environment, setEnvironment] = useState(null);
  const [summaryAi, setSummaryAi] = useState(null);
  const [suggestionAi, setSuggestionAi] = useState(null);
  const [sourcesAi, setSourcesAi] = useState(null);
  const [isAiLoading, setIsAiLoading] = useState(false);
  const [caret, setCaret] = useState(null);
  const [value, setValue] = useState('');
  const [showAttachMenu, setShowAttachMenu] = useState(false);
  const [showAiMenu, setShowAiMenu] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showTemplateSelector, setShowTemplateSelector] = useState(false);
  const { selectedChatId, isMessageSent, setIsMessageSent } = useSocketContext();
  
  useEffect(() => {
    setEnvironment(getEnvironment());
  }, []);

  useWhenComponentBlur(() => {
    setShowEmojiPicker(false);
  }, emojiPickerRef);

  useWhenComponentBlur(() => {
    setShowTemplateSelector(false);
  }, templateSelectorRef);

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [value, isMessageSent]);

  const closeAttachMenu = () => {
    if (showAttachMenu) {
      setShowAttachMenu(false);
    }
  };
  const setSuggestionAsValue = (suggestion) => {
    setValue(suggestion || '');
  };
  const handleKeyUp = (e) => {
    switch (e.key) {
      case 'Escape':
        closeAttachMenu();
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);
    return () => window.removeEventListener('keyup', handleKeyUp);
  });
  /* useEffect(() => {
    const autosize = () => {
      setTimeout(() => {
        inputRef.current.style.cssText = 'height:0px';
        const height = Math.min(20 * 5, inputRef.current.scrollHeight);
        divRef.current.style.cssText = `height: ${height} px; width: 100%;`;
        inputRef.current.style.cssText = `height: ${height} px`;
      },0);
    }
    if (inputRef && inputRef.current) {
      inputRef.current.addEventListener('keydown', autosize);
    }
  }) */
    useEffect(() => {
      const autosize = () => {
        if (inputRef.current) {
          const style = window.getComputedStyle(inputRef.current);
          let lineHeight = parseInt(style.lineHeight, 10);
    
          if (Number.isNaN(lineHeight)) {
            lineHeight = 20;
          }
    
          inputRef.current.style.height = '40px';
    
          const scrollHeight = inputRef?.current?.scrollHeight;
          const maxHeight = lineHeight * 3;
    
          inputRef.current.style.height = `${Math.max(40, Math.min(scrollHeight, maxHeight))}px`;
        }
      };
    
      if (inputRef.current) {
        autosize();
        inputRef.current.addEventListener('input', autosize);
      }
    
      return () => {
        if (inputRef.current) {
          inputRef.current.removeEventListener('input', autosize);
        }
      };
    }, [value]);
    

  useEffect(() => {
    window.addEventListener('click', () => {
      closeAttachMenu();
    });
    return () =>
      window.removeEventListener('click', () => {
        closeAttachMenu();
      });
  });

  const onChangeHandler = (e) => {
    e.preventDefault();
    setValue(e.target.value);
  };

  const sendMessageHandler = () => {
    const regExpressionWhiteSpaces = /^\s*$/;
    const isWhiteSpaces = regExpressionWhiteSpaces.test(value);
  
    if (value !== '' && !isWhiteSpaces) {
      const message = {
        conversationSessionId: selectedChatId,
        content: {
          body: value.trim(),
        },
        sendType: MSG_SEND_TYPE.OPERATOR,
        status: 'queued',
        contentType: MSG_CONTENT_TYPES.TEXT,
      };
  
      setGlobalState((state) => {
        const aux = [...conversations];
        if (aux.length > 0) {
          const index = _.findIndex(aux, (conv) => conv.conversationSessionId === selectedChatId);
          const newTimespan = new Date();
          if (message.status === 'queued' || !message.status) {
            setScrollAllowed({ isAllowed: true, options: { behavior: 'smooth' } });
            message.timespan = newTimespan;
            aux[index].messages.push(message);
          }
          return { ...state, conversations: aux };
        }
      });
      sendMessage(message);
      setIsMessageSent(false);
      setValue('');
  
      // Set textarea height 40px
      if (inputRef.current) {
        setTimeout(() => {
          inputRef.current.style.height = '40px';
        }, 0);
      }
    }
  };
  

  if (isMessageSent) {
    setIsMessageSent(false);
  }

  const introSubmit = (e) => {
    if ((e.shiftKey && e.which === KEYS.INTRO)) {
      e.preventDefault();
      const position = inputRef.current.selectionStart;
      const newValue = `${value.substring(0, position)}\n${value.substring(position)}`;
      setValue(newValue);
      inputRef.current.selectionStart = position + 1;
      inputRef.current.selectionEnd = position + 1;
    } else if (e.which === KEYS.INTRO) {
      e.preventDefault();
      sendMessageHandler();
    }
  };
  const updateAiInfo = (summary, suggestion, sources) => {
    setSummaryAi(summary);
    setSuggestionAi(suggestion);
    setSourcesAi(sources);
    setIsAiLoading(false);
  } 

  const loadInfoAi = async () => {
    setIsAiLoading(true);
    let summary = null;
    let suggestion = null;
    let sources = null;
  
    try {
      // Obtener integración de la IA
      const integration = await ChatGenService.getIntegrationIagen();
      const agentId = integration?.config?.iaGenDefaultHelperBot;
  
      const selectedConv = conversations.find(
        (el) => el.conversationSessionId === selectedChatId
      );
  
      const lastMessage =
        selectedConv?.messages?.length &&
        selectedConv.messages[selectedConv.messages.length - 1];
  
      summary = await ContactsService.getSummaryHistory(selectedChatId);
  
      // Pedir sugerencias y fuentes
      const response = await ChatGenService.askForSuggestion(
        selectedChatId,
        lastMessage?.messageId,
        summary,
        agentId
      );
      suggestion = response?.suggestion || null;
      sources = response?.sources || null;
    } catch (error) {
      console.error('Error getting suggestions:', error);
    } finally {
      setIsAiLoading(false);
    }
  
    // Actualizar información de la IA
    updateAiInfo(summary, suggestion, sources);
  };

  const onAiIconClicked = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    console.log('clicked aiicon', showAiMenu)
    setShowAiMenu(!showAiMenu);
    await loadInfoAi();
  }

  const onAttachClicked = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShowAttachMenu(!showAttachMenu);
    setShowTemplateSelector(false);
  };

  const handleTemplatesClicked = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShowAttachMenu(false);
    setShowTemplateSelector(true);
  };

  const addEmoji = (emoji) => {
    const { start, end } = caret || {};
    setValue((val) => val.substring(0, start) + emoji + val.substring(end));
    setShowEmojiPicker(false);
  };

  const handleAddTemplate = () => {
    setShowTemplateSelector(false);
    onAddTemplate();
  };
  return (
    <SendInputStyled disabled={isMessageSent} showAttachMenu={showAttachMenu} showTemplateSelector={showTemplateSelector} showOnlyButton={showOnlyButton}>
      {!showOnlyButton ? <>
        <IconButton
          toolTip={i18n.attachMenu.attachButtonTooltip}
          toolTipPosition="top"
          toolTipPositionOffset={5}
          disableToolTip={isMediumScreen}
          disabled={isMessageSent}
          onClick={onAttachClicked}
          className="attach-button"
        >
          <AttachIcon className="attach-icon ts" />
        </IconButton>
        <IconButton
          toolTip={i18n.templateSelector.tooltip}
          toolTipPosition="top"
          toolTipPositionOffset={5}
          disableToolTip={isMediumScreen}
          disabled={isMessageSent}
          onClick={handleTemplatesClicked}
          className="fragments-button"
        >
          <PapersIcon className="fragments-icon ts" />
        </IconButton>
        {showEmojiPicker && (
          <div className="emoji-picker" ref={emojiPickerRef}>
            <Picker onSelect={(emoji) => addEmoji(emoji.native)} set="facebook" title={i18n.emojiPicker.title} i18n={i18n.emojiPicker} />
          </div>
        )}
        <IconButton disabled={isMessageSent} onClick={() => setShowEmojiPicker((showing) => !showing)} className="emoji-button">
          <EmojiIcon className="emoji-icon ts" />
        </IconButton>
        {environment === 'dev' ? <IconButton
        
        
          toolTip={i18n.aiMenu.aiTooltipIcon}
          toolTipPosition="top"
          toolTipPositionOffset={5}
          disableToolTip={isMediumScreen}
          disabled={false} // todo: add condition 
          onClick={onAiIconClicked}
          className="attach-button"
        
        >
          <AiIcon className="ai-icon ts" />
        </IconButton> : <div/>}
        <div className="div-textarea" style={{ width: '100%' }} ref={divRef}>
          <textarea
            ref={inputRef}
            disabled={isMessageSent}
            onKeyDown={introSubmit}
            value={value}
            onChange={onChangeHandler}
            onBlur={({ target }) => setCaret({ start: target.selectionStart, end: target.selectionEnd })}
            type="text"
            placeholder={i18n.messageInput.placeholder}
          />
        </div>

        <IconButton disabled={isMessageSent} onClick={sendMessageHandler} className="send-button">
          <SendIcon className="send-icon ts" />
        </IconButton>
        <AttachMenuSelector hideToolTip={isMediumScreen} onAttachEvent={onAttachEvent} onSelectMenu={closeAttachMenu} i18n={i18n} />
        <MinimalDrawer isOpen={showAiMenu}
          summary={summaryAi} suggestion={suggestionAi}
          setIsOpen={setShowAiMenu} i18n={i18n} isLoading={isAiLoading}
          saveSuggestion={setSuggestionAsValue}
          sources={sourcesAi}
        />
        <TemplateSelector
          i18n={i18n}
          title={i18n.templateSelector.title}
          list={templateList}
          onTemplateSelected={(fragment) => setValue(fragment)}
          onAddTemplate={handleAddTemplate}
          onDeleteTemplate={onDeleteTemplate}
          ref={templateSelectorRef}
          hideToolTip={isMediumScreen}
          isAuditor={isAuditor}
          sendTemplate={sendTemplate}
          setTemplateToPreview={setTemplateToPreview}
          disabledTemplateSending={disabledTemplateSending}
        />
        {/* isAuditor && auditorTemplate && <button type="button" className="template-button-send" onClick={sendTemplate}>{`${i18n.chats.sendTemplate} ${auditorTemplate?.title}`}</button> */}
        <ReactTooltip type="info" />
      </> : <button type="button" className="assign-self-button" onClick={assignConvToMyself}>{i18n.messageInput.assignConvToMyself}</button>
    }
    </SendInputStyled>
  );
}

SendMessageInput.propTypes = {
  i18n: PropTypes.object,
  templateList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      message: PropTypes.string,
    })
  ),
  onAttachEvent: PropTypes.func,
  onAddTemplate: PropTypes.func,
  onDeleteTemplate: PropTypes.func,
  assignConvToMyself: PropTypes.func,
  sendTemplate: PropTypes.func,
  showOnlyButton: PropTypes.bool,
  isAuditor: PropTypes.bool,
  conversations: PropTypes.array,
  setGlobalState: PropTypes.func,
  setScrollAllowed: PropTypes.func,
  disabledTemplateSending: PropTypes.bool,
  setTemplateToPreview: PropTypes.func,
};

export default withI18n(SendMessageInput);
