export default {
  auth: {
    loading: 'Cargando...',
    waContCent: 'WhatsApp Control Center',
  },
  errors: {
    unauthorized:
      'No tiene los permisos correspondientes para acceder a la herramienta. Si se trata de un error, contacte con el administrador de su cuenta o con el departamento de soporte de Indigitall.',
    goBackToIndigitall: 'Volver a Indigitall',
    imageFileNotAvailable: 'La imagen no está disponible',
    videoFileNotAvailable: 'El vídeo no está disponible',
    locationFileNotAvailable: 'El mapa no está disponible',
    documentFileNotAvailable: 'El documento no está disponible',
    audioFileNotAvailable: 'El audio no está disponible',
    videoNotSupported: 'El navegador no admite este vídeo',
    socketDisconnected:
      'Ha habido un problema con la conexión. Por favor refresque la página. Si el problema persisite, contacte con el administrador o el equipo de soporte de Indigitall.',
    fileTooBig: 'El archivo es demasiado grande. Por favor, elija otro archivo.',
    fileNoValid: 'El archivo seleccionado no es válido. Por favor, elija otro archivo.',
    tooltipError: 'Ha ocurrido un error. Mensaje no enviado.',
    noMatch: 'No se encontró la página. Pulsa en el botón de abajo para redirgirte a la página de inicio.',
    banUser: 'Ha habido un problema al bloquear al usuario',
    requiredField: 'Campo requerido',
    generalError: 'Ha ocurrido un error',
  },
  agentStats: {
    title: 'Estadísticas',
    waitingTime: 'Tiempo medio de espera: ',
    maxWaitingTime: 'Tiempo máximo de espera: ',
    average: 'de Media',
    max: 'm máx.',
    queuedUsers: 'Usuarios en cola: ',
    onlineAgents: 'Agentes Online: ',
  },
  chats: {
    createLabel: 'Crear etiqueta',
    editLabel: 'Editar',
    deleteLabel: 'Borrar',
    placeholderSelect: 'Seleccionar etiquetas...',
    search: 'Buscar...',
    ago: 'hace',
    day: 'día',
    month: 'mes',
    year: 'año',
    conversations: 'Conversaciones',
    allConversations: 'Todas las conversaciones',
    conversation: 'Conversación',
    singleConversation: 'Conversación',
    lastMessage: 'Ult. mensaje',
    lastMsgAgent: 'Ult. msg agente',
    lastMsgUser: 'Ult. msg usuario',
    contactId: 'Facebook ID',
    noNewConversations: 'No aceptar nuevas conversaciones',
    acceptNewConversation: 'Aceptar nuevas conversaciones',
    writeNewMessage: 'Escribe un nuevo mensaje',
    emptyMessagesTitle: 'No hay mensajes',
    emptyMessagesText: 'Seleccione una conversación del panel para ver los mensajes.',
    emptyConversations: 'Sin conversaciones',
    webchat: 'Web',
    whatsapp: 'WhatsApp',
    messenger: 'Messenger',
    showMoreMessages: 'Mostrar más mensajes',
    endChatModal: {
      title: '¿Desea finalizar la conversación?',
      body:
        'Si se finaliza la conversación, el usuario dejará de hablar con un agente humano y volverá a hablar con el chat bot hasta que vuelva a pedir contactar de nuevo con un agente.',
      cancel: 'cancelar',
      end: 'finalizar',
    },
    transferChatModal: {
      title: '¿Desea transferir la conversación?',
      body:
        'Puede transferir la conversación a los siguientes agentes, si la operación no es posible, la conversación permanecerá asignada al agente original.',
      cancel: 'cancelar',
      noAvailableAgents: 'No hay más agentes disponibles en este momento a los que transferirles esta conversación.',
      end: 'transferir',
    },
    template: 'Plantilla',
    sent: 'enviada',
    sendTemplate: 'Enviar plantilla',
    notAbleToShowMessage: 'Este mensaje no puede ser mostrado',
    notAbleToShowImageMessage: 'Este mensaje es una imagen y no puede ser mostrado',
    notAbleToShowVideoMessage: 'Este mensaje es un vídeo y no puede ser mostrado',
    notAbleToShowDocMessage: 'Este mensaje es un documento y no puede ser mostrado',
    notAbleToShowLocationMessage: 'Este mensaje contiene una ubicación y no puede ser mostrado',
    notAbleToShowContactMessage: 'Este mensaje contiene información de un contacto y no puede ser mostrado',
    unknownDocumentCaption: 'Documento',
    myStatus: 'Mi estado:',
    unavailable: 'No disponible',
    available: 'Disponible',
    online: 'Online',
    unknown: 'Desconocido',
    transferConvToolTip: 'Transferir conversación',
    endconvToolTip: 'Finalizar conversación',
    scrollToBotToolTip: 'Ir al final',
    messageWasDeleted: 'Este mensaje fue eliminado',
    messageNotDelivered: 'No se pudo mandar el mensaje, inténtelo de nuevo mas tarde',
    sfDetails: 'Ficha de Salesforce',
    banUser: 'Bloquear usuario',
    banConversation: 'Bloquear conversación',
    banConversationList: 'Lista de conversaciones bloqueadas',
    ban: 'Bloquear',
    conversationId: 'ID de conversación',
    date: 'Fecha',
    unbanTooltip: 'Eliminar bloqueo',
    reasonBlocking: 'Indica la razón del bloqueo o cualquier comentario acerca del mismo',
    bannedSuccesfully: 'El usuario ha sido bloqueado',
    unBannedSuccessfully: 'El usuario ha sido desbloqueado',
    listBannedUsers: 'Lista de usuarios bloqueados',
    areYouSureBanUserId: '¿Deseas bloquear la conversación con ID',
    infoBanUser: '? Esta conversación no podrá ser abierta de nuevo por el usuario mientras esté bloqueada',
    reason: 'Razón',
    logout: 'Cerrar sesión',
    flowResponse: 'Respuesta del flow',
    key: 'Clave',
    value: 'Valor',
  },
  messageInput: {
    placeholder: 'Escribe tu mensaje...',
    sendTemplate: 'Envíar plantilla',
    assignConvToMyself: 'Asignarme la conversación',
  },
  attachMenu: {
    image: 'Imagen',
    video: 'Vídeo',
    document: 'Documento',
    audio: 'Audio',
    attachButtonTooltip: 'Adjuntar archivo',
  },
  aiMenu: {
    aiTooltipIcon: 'Obtener respuesta sugerida',
  },
  attachImageModal: {
    title: 'Enviar imagen',
    button: 'Subir imagen',
    send: 'Enviar',
    limitations: 'El tamaño del archivo de imagen no ha de ser superior a 5 MB',
    supportedFormat: 'Formatos de imagen admitidos: .jpeg, .jpg y .png',
  },
  attachVideoModal: {
    title: 'Enviar vídeo',
    button: 'Subir vídeo',
    send: 'Enviar',
    limitations: 'El tamaño del archivo de vídeo no ha de ser superior a 16 MB',
    supportedFormat: 'Formatos de vídeo admitidos: .mp4 y .3gpp',
  },
  attachDocumentModal: {
    title: 'Enviar documento',
    button: 'Subir documento',
    send: 'Enviar',
    limitations: 'El tamaño del archivo del documento no ha de ser superior a 100 MB',
    supportedFormat: '',
  },
  attachAudioModal: {
    title: 'Enviar audio',
    button: 'Subir audio',
    send: 'Enviar',
    limitations: 'El tamaño del archivo de audio no ha de ser superior a 16 MB',
    supportedFormat: 'Formatos de audio admitidos: .acc, .ogg, .amr, .mpeg y .mp4',
  },
  templateSelector: {
    title: 'Seleccione la plantilla',
    titleRadios: 'Puedes filtrar por rol auditor',
    auditor: 'Auditor',
    all: 'Todas',
    tooltip: 'Usar plantilla como mensaje',
    addTemplate: 'Añadir plantilla',
    deleteTemplate: 'Eliminar plantilla',
    sendTemplate: 'Enviar plantilla',
    createdTemplate: 'Se ha creado la plantilla',
    deletedTemplate: 'Se ha borrado la plantilla',
    errorLoadingTemplates: 'Ha habido un problema cargando la plantilla',
    errorCreatingTemplate: 'Ha habido un problema creando la plantilla',
    errorDeletingTemplate: 'Ha habido un problema borrando la plantilla',
    moreActions: 'Más acciones',
    viewTemplate: 'Ver plantilla',
  },
  templateModal: {
    deleteTitle: 'Eliminar plantilla',
    areYouSureDelete: '¿Seguro que quieres borrar la plantilla?',
    createTitle: 'Crear plantilla',
    editTitle: 'Editar plantilla',
    titlePlaceholder: 'Nombre plantilla',
    messagePlaceholder: 'Plantilla',
  },
  toast: {
    correctConvTransfer: 'La conversación se ha transferido a otro agente correctamente.',
    sameAgentAfterTransfer: 'No hay agentes disponibles. La conversación se mantendrá abierta para este agente.',
    errorTransfer: 'No hay agentes disponibles. Se va a cerrar la conversación.',
    error500Attachfile:
      'Ha ocurrido un error. Inténtelo más tarde y si el problema persiste, póngase en contacto con el equipo de soporte técnico de Indigitall.',
    supervisorCloseConversation: 'Un supervisor ha cerrado la conversación ',
    errorLoadUsers: 'Error al tratar de cargar los usuarios disponibles.',
  },
  buttons: {
    cancel: 'Cancelar',
    download: 'Descargar',
    downloadImage: 'Descargar imagen',
    downloadVideo: 'Descargar vídeo',
    downloadDocument: 'Descargar documento',
    downloadAudio: 'Descargar audio',
    save: 'Guardar',
    backToHome: 'Volver al inicio',
    transfer: 'Transferir',
  },
  multimedia: {
    playVideo: 'Pulsa para ver el vídeo',
  },
  modalSF: {
    title: 'Abrir caso en Salesforce',
    body: 'Rellena la información del caso en Salesforce',
    type: 'Selecciona el tipo',
    reason: 'Selecciona el motivo',
    priority: 'Selecciona la prioridad',
    description: 'Rellena la descripción',
    labelType: 'Tipo',
    labelReason: 'Motivo',
    labelPriority: 'Prioridad',
    labelDescription: 'Descripción',
    labelExternalId: 'External Id',
    saveAndCloseConv: 'Guardar y Cerrar',
  },
  modalCloseForm: {
    saveAndCloseConv: 'Guardar y Cerrar',
  },
  localPush: {
    title: 'Indigitall Contact Center',
    body: 'Tienes mensajes sin leer',
  },
  supervisor: {
    noMessagesAvailable: 'No hay mensajes disponibles. Vuelva atrás y abra la conversación de nuevo.',
    conversationsOf: 'Conversaciones del agente {agentName}',
    noConversationsToShow: 'No hay conversaciones que mostrar',
    agent: 'Agente #{agentId}',
    headerText: 'Supervisor de Conversaciones',
    agentsTab: 'Agentes',
    statsTab: 'Estadísticas',
    convsTab: 'Conversaciones sticky',
    online: 'Conectado',
    offline: 'Desconectado',
    seeConversations: 'Ver conversaciones',
    seeMessages: 'Ver mensajes',
    activeConversations: {
      singular: 'Conversación activa',
      plural: 'Conversaciones activas',
    },
    lastMessageReceived: 'Último mensaje recibido',
    lastMessageSent: 'Último mensaje enviado',
    lastTimeConnected: 'Se conectó por última vez el',
    notAvailableAKA: 'N/D',
    changeToAvailable: 'Cambiar estado a Disponible',
    changeToUnavaible: 'Cambiar estado a No Disponible',
    statistics: {
      titleAgents: 'Agentes',
      titleUsers: 'Usuarios',
      titleConversations: 'Conversaciones',
      titleChannels: 'Canales',
      registeredAgentsPlural: 'agentes registrados',
      registeredAgentsSing: 'agente registrado',
      onlineAgentsPlural: 'agentes conectados',
      onlineAgentsSing: 'agente conectado',
      availableAgentsPlural: 'agentes disponibles',
      availableAgentsSing: 'agente disponible',
      quededUsersPlural: 'usuarios en cola',
      quededUsersSing: 'usuario en cola',
      avgWaitTimeStr: 'El tiempo media de espera es de',
      maxWaitTimeStr: 'El tiempo máximo de espera es de',
      todayConvsAttendedPlural: 'conversaciones atendidas hoy',
      todayConvsAttendedSing: 'conversación atendida hoy',
      openedConvsNowPlural: 'conversaciones abiertas ahora mismo',
      openedConvsNowSing: 'conversación abierta ahora mismo',
      convsPerAgentSing: 'conversación asignada por agente',
      convsPerAgentPlural: 'conversaciones asignadas por agente',
      convSing: 'conversación',
      convPlural: 'conversaciones',
    },
    modalTransfer: {
      title: 'Trasferir conversación #{conversationId}',
      body: 'Selecciona al agente al que deseas transferir esta conversación',
      selectPlaceholder: 'Escribe el nombre de agente para buscar',
      agent: 'Agente #{agentId}',
      selectNoOptionsMessage: 'No hay resultados',
    },
    goBack: 'Volver',
  },
  emojiPicker: {
    title: 'Elige un emoji',
    search: 'Buscar',
    notfound: 'Emoji no encontrado',
    skintext: 'Selecciona el tono de piel',
    categories: {
      search: 'Resultados de búsqueda',
      recent: 'Los más usados',
      people: 'Smileys & Personas',
      nature: 'Animales y Naturaleza',
      foods: 'Comida y Bebida',
      activity: 'Actividad',
      places: 'Viajes y Lugares',
      objects: 'Objetos',
      symbols: 'Símbolos',
      flags: 'Banderas',
      custom: 'Custom',
    },
  },
  login: {
    recoveryPass: 'Recuperar contraseña',
    title: 'Login',
    email: 'Email',
    pass: 'Contraseña',
    login: 'Acceder',
    doubleFactorSummary: 'Inserta el código de verificación que has recibido en tu email, valido durante:',
    verificationCode: 'Código de verificación',
    send: 'Enviar',
    backToLogin: 'Volver a Login',
    refreshToken: 'Obtener nuevo código',
    userLockedInactivity: 'Usuario bloqueado por inactividad, contacte con su administrador',
    tooOldPassword: 'Tu contraseña es demasiado antigua. Se ha enviado un correo a tu email, sigue las instrucciones para cambiarla',
    tooManyAttempts: 'Demasiados intentos de login, usuario bloqueado, inténtelo más tarde o contacte con su administrador.',
    invalidBadges: 'Credenciales no válidos',

    insertVerificationCode: 'Inserta el código de verificación que has recibido en tu email, valido durante:',
    invalidTotpError: 'El código introducido no es válido',
    tokenExpiredError: 'El código introducido ha expirado',
    sendNewCode: 'Obtener nuevo código',
    isNotValid: 'No es válido',
    invitationExpired: 'Invitación caducada',
  
  },
  applications: {
    title: 'Selecciona un proyecto',
    noApplications: 'No hay proyectos disponibles',
    loadMore: 'Cargar más',
    applicationSelected: 'Proyecto seleccionado',
    change: 'Cambiar',
  },
  modalInstallApp: {
    title: 'Añadir Indigital Contact Center a tu Pantalla de inicio',
    body: `Para tener una mejor experiencia de usuario y una mayor facilidad de acceso,
      te recomendamos instalar nuestra aplicación en tu dispositivo móvil`,
    install: 'Instalar',
    cancel: 'Cancelar',
  },
  previewTemplateModal: {
    title: 'Previsualización de plantilla',
    body: '',
    close: 'Cerrar',
    send: 'Enviar',
  },
  drawer: {
    documentation: 'Documentación',
    suggestedReply: 'Respuesta sugerida',
    summary: 'Resumen',
    useSuggestion: 'Usar sugerencia',
    viewDoc: 'Ver documentación',
    notAvailableSuggestion: 'No hay sugerencia disponible.',
    notAvailableSummary: 'No hay resumen disponible',
    sources: 'Fuentes',
    notAvailableSources: 'No hay fuentes disponibles.'
  },
  stickyTable: {
    free: 'Liberar',
    assignAgent: 'Asignar Agente',
    search: 'Buscar agente',
    contact: 'Contacto',
    agent: 'Agente',
    actions: 'Acciones',
    searchDots: 'Buscar...',
  },
  stickyModal: {
    titleAdd: 'Selecciona agente sticky',
    titleDelete: 'Confirmar liberación',
    bodyAddAgent:
      'Puedes seleccionar el agente sticky que gestionará las conversaciones seleccionadas.',
    bodyDeleteAgent: 'Si presionas aceptar se liberarán las conversaciones seleccionadas',
    cancel: 'Cancelar',
    accept: 'Aceptar',
    noAvailableAgents: 'No hay más agentes disponibles en este momento a los que transferirles esta conversación.',
    end: 'transferir',
    
  },
};
