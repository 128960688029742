import * as api from '../api';

class AgentsService {
  addAgentToConv = async (
    contactId,
    agentId
  ) => {
    try {
      const suggestionsResp = await api.setAgentSticky({ 
        agentId,
        contactId,
      });
      if (suggestionsResp) {
        console.log('suggestionsResp', suggestionsResp);
        return suggestionsResp;
      }
    } catch (error) {
      throw new Error(error);
    }
  };
/*   getChatGenAgent = async () => {
    try {
      const agent = await api.getChatGenAgent();
      console.log('agent received', agent);
      if (agent) {
        return agent;
      }
    } catch (error) {
      throw new Error(error);
    }
  }; */
  getIntegrationIagen = async () => {
    try {
      const integration = await api.getIntegrationChatGen();
      console.log('integrationIaGen', integration)
      return integration;
    } catch (error) {
      throw new Error(error);
    }
  };
}

const instance = new AgentsService();

export default instance;
