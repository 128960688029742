import LocalizedStrings from 'react-localization';
import en from './en';
import es from './es';
import ptBR from './ptBR';

import 'moment/locale/es';

export default new LocalizedStrings({
  en,
  es,
  ptBR,
});
