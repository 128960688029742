import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import _, { debounce } from 'lodash';
import { toast } from 'react-toastify';
import { withI18n } from '../../../hocs';
import { ButtonAcceptChats, SettingsIcon, Overlay, IconButton, SelectApplication } from '../../atoms';
import { ChatCell, ModalBannedUsers } from '../../molecules';
import { ReactComponent as LogoutIcon } from '../../../assets/images/logout.svg';
import bp from '../../../config/breakpoints';
import { AGENT_STATUS, USER_ROL } from '../../../models';
import { useSocketContext } from '../../../sockets/context';
import { sendAgentStatus, auditorSubscribeToConversation } from '../../../sockets/emit';
import { AuthService, BanUserService, ContactsService, TopicsService } from '../../../services';
import useWhenComponentBlur from '../../molecules/send-message-input/useWhenComponentBlur';
import { ChatStyled } from './styled';
import Paths from '../../../config/routes';
import { formatDate } from '../../utils/utils';


function findHighestIdWithSendType(arr, sendType, locale) {
    if (!arr || !arr.length) {
      return '';
    }
    const filteredArr = arr.filter(item => item.sendType === sendType);

    if (filteredArr.length === 0) {
        return '';
    }
    const highestIdObj = filteredArr.reduce((max, obj) => {
        return obj.messageId > max.messageId ? obj : max;
    });
    return highestIdObj.timespan ? formatDate(highestIdObj.timespan, locale) : '';
}
const ConversationsList = ({ transferringConvs, conversations, selectedChat, chatClickHandler, newMessages, convId = 'conversationSessionId', auditor, i18n }) => {
  const list = conversations && conversations.length && conversations.map((conv) => {
    const convFromNewMessages = _.find(newMessages, (c) => conv.conversationSessionId === c.convId);
    const isContactId = conv && conv.contactId && (conv.channel === 'whatsapp' || conv.channel === 'cloudapi');
    let chatLabel;
    if (isContactId) {
        chatLabel = `+${conv.contactId}`;
    } else if (conv && conv.contactId) {
        chatLabel = `#${conv.contactId}`;
    } else {
        chatLabel = `${i18n.chats.singleConversation} #${conv.conversationSessionId}`;
    }
    const locale = navigator.language;
    const dateLastAgentMessage = findHighestIdWithSendType(conv?.messages, 'operator', locale);
    const dateLastUserMessage = findHighestIdWithSendType(conv?.messages, 'input', locale);
    return (
      <ChatCell
        externalUrl={conv.externalUrl}
        externalId={conv.externalId}
        selected={selectedChat === conv.conversationSessionId}
        onSelectChat={chatClickHandler}
        chatLabel={chatLabel}
        key={conv[convId]}
        dateLastUserMessage={dateLastUserMessage}
        dateLastAgentMessage={dateLastAgentMessage}
        chatId={conv[convId]}
        profileName={conv.profileName}
        convNum={conv[convId]}
        channel={conv.channel}
        topics={conv?.topics}
        newMessages={convFromNewMessages ? convFromNewMessages.count : 0}
        showTransferIcon={auditor ? false : transferringConvs.find((id) => id === conv.conversationSessionId)}
      />
    );
  });

  return <ul>{list}</ul>;
};
ConversationsList.propTypes = {
  conversations: PropTypes.array,
  selectedChat: PropTypes.number,
  chatClickHandler: PropTypes.func,
  newMessages: PropTypes.array,
  transferringConvs: PropTypes.array,
  auditor: PropTypes.bool,
  convId: PropTypes.number,
  i18n: PropTypes.object,
};

const AllConversationsList = ({
  selectedChat, chatClickHandler, newMessages, convId = 'id',
  conversations,
}) => {

  const list = (conversations || []).map((conv) => {
    const convFromNewMessages = _.find(newMessages, (c) => conv.conversationSessionId === c.convId);
    const isContactId = conv && conv.contactId && (conv.channel === 'whatsapp' || conv.channel === 'cloudapi');
    const locale = navigator.language;
    /* const formatDateString = locale === 'es' ? 'DD-MM-YYYY HH:mm:ss' : 'YYYY-MM-DD hh:mm:ss A'; */
    const dateLastInputMessage = conv.lastInputMessage ? formatDate(conv.lastInputMessage, locale) : '';
    const chatLabel = isContactId ? `+${conv.contactId}` : `#${conv && conv.contactId}`;
    return (
      <ChatCell
        externalUrl={conv.externalUrl}
        externalId={conv.externalId}
        selected={selectedChat === conv.conversationSessionId}
        onSelectChat={chatClickHandler}
        chatLabel={chatLabel}
        key={conv[convId]}
        chatId={conv[convId]}
        profileName={conv.profileName}
        dateLastInputMessage={dateLastInputMessage}
        convNum={conv[convId]}
        channel={conv.channel}
        newMessages={convFromNewMessages ? convFromNewMessages.count : 0}
        showTransferIcon={false}
      />
    );
  });

  return <ul>
    {list}
    </ul>;
};
AllConversationsList.propTypes = {
  conversations: PropTypes.array,
  selectedChat: PropTypes.number,
  chatClickHandler: PropTypes.func,
  newMessages: PropTypes.array,
  convId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};




const ChatListHeader = withI18n(({ i18n, title, options, onOptionSelected }) => {
  const isMediumScreen = useMediaQuery({ query: `(max-width: ${bp.md}px)` });
  const history = useHistory();
  const [showMenu, setShowMenu] = useState(false);
  const overlayRef = useRef(null);
  const { applicationName, applicationImage } = AuthService.getApplicationInfo();

  useWhenComponentBlur(() => {
    setShowMenu(false);
  }, overlayRef);

  const logout = () => {
    AuthService.logout();
    history.replace(Paths.LOGIN);
  };

  return (
    <div className="chat-list-header">
      <h1>{title}</h1>
      {options && (
        <div>
          <IconButton onClick={() => setShowMenu((current) => !current)} type="button">
            <SettingsIcon className="chat-list__settings-icon" />
          </IconButton>
          <Overlay ref={overlayRef} className="chat-list__settings__menu" show={showMenu}>
            {options.map((option) => (
              <div key={`option-${option.id}`} className="option-item" onClick={() => onOptionSelected(option.id)}>
                {option.label}
              </div>
            ))}
            {!isMediumScreen && (
              <>
                <div key="logout" className="option-item logout-item" onClick={logout}>
                  <LogoutIcon className="logout-icon" />
                  {i18n.chats.logout}
                </div>
                <div className="select-app-wrapper">
                  <SelectApplication projectName={applicationName} projectImage={applicationImage} />
                </div>
              </>
            )}
          </Overlay>
        </div>
      )}
    </div>
  );
});

ChatListHeader.propTypes = {
  title: PropTypes.string,
  options: PropTypes.array,
  i18n: PropTypes.object,
  onOptionSelected: PropTypes.func,
};

const ChatEmpty = withI18n(({ i18n }) => (
  <div className="empty-convs-holder">
    <p className="empty-convs-text">{i18n.chats.emptyConversations}</p>
  </div>
));

const Chat = ({ i18n, onChatClick }) => {
  const {
    conversations,
    conversationsAvailableAuditor,
    setConversationsAvailableAuditor,
    addSubscribedAuditorConv,
    selectedChatId,
    setSelectedChatId,
    newMessages,
    convTransferIds,
    setScrollAllowed,
    setAgentStatus,
    agents,
    userInfo,
    bannedUsers,
    deleteBannedUser,
    setTopics,
    setConversations,
    topics,
    auth
  } = useSocketContext();
  const [showBannedListModal, setShowBannedListModal] = useState(false);
  const [findValue, setFindValue] = useState('');
  const auditor = AuthService.getUserRol() === USER_ROL.AUDITOR;

const updateTopics = async () => {
  const applicationId = AuthService.getApplicationId();
  const topicsToSet = await TopicsService.obtainTopics(applicationId);
  setTopics(topicsToSet);
};

useEffect(() => {
  if (auth?.token)
    updateTopics();
}, [auth]);
useEffect(() => {
  let theresChanges = false;
  if (topics?.length && conversations?.length) {
    const updatedConversations = conversations.map(conversation => {
      if (conversation.topics?.length && conversation.topics.every(topic => typeof topic === 'number')) {
        theresChanges = true;
        const mappedTopics = conversation.topics.map(topicId => {
          const topic = topics.find(t => t.id === topicId);
          return {
            id: topicId,
            name: topic?.name || 'Unknown',
            code: topic?.code,
          };
        });
        return {
          ...conversation,
          topics: mappedTopics
        };
      }
      return conversation;
    });
    if (theresChanges) {
      setConversations(updatedConversations);
    }
  }
}, [topics, conversations]);
const searchConversationsByConvId = async (find) => {
  const applicationId = AuthService.getApplicationId();
  const data = await ContactsService.getContacts(applicationId, 100, find, null);
  setConversationsAvailableAuditor([...data]);
};

const debouncedSearchConversationsByConvId = useRef(
  debounce((find) => searchConversationsByConvId(find), 900)
).current;

useEffect(() => {
    debouncedSearchConversationsByConvId(findValue);
}, [findValue]);
  

  const agent = _.find(agents, (val) => val.agentId === userInfo.agentId);
  const options = [{ id: 'banList', label: i18n.chats.listBannedUsers }];
  const optionsActions = {
    banList: () => {
      setShowBannedListModal(true);
    },
  };
  const handleUnbanUser = (user) => {
    BanUserService.unBan([user]).then(() => {
      deleteBannedUser(user.conversationSessionId);
      toast(i18n.chats.unBannedSuccesfully, { type: toast.TYPE.SUCCESS });
    });
    setShowBannedListModal(false);
  };
  const setAgentStatusHandler = () => {
    if (agent && agent.status === AGENT_STATUS.AVAILABLE) {
      sendAgentStatus(AGENT_STATUS.HALTING);
      setAgentStatus(AGENT_STATUS.HALTING);
    } else {
      sendAgentStatus(AGENT_STATUS.AVAILABLE);
      setAgentStatus(AGENT_STATUS.AVAILABLE);
    }
  };

  return (
    <ChatStyled auditor={auditor}>
      <ChatListHeader title={i18n.chats.conversations} options={options} onOptionSelected={(selected) => optionsActions[selected]()} />
      {conversations?.length === 0 ? (
        <ChatEmpty auditor={auditor} />
      ) : (
        <ConversationsList
          transferringConvs={convTransferIds}
          conversations={conversations}
          selectedChat={selectedChatId}
          i18n={i18n}
          auditor={auditor}
          chatClickHandler={(id) => {
            setSelectedChatId(id);
            setScrollAllowed(true);
            onChatClick();
          }}
          newMessages={newMessages}
        />
      )}
      {auditor ? (
        <>
          <div className="chat-list-header">
            <h1>{i18n.chats.allConversations}</h1>
          </div>
          <div style={{ marginTop: 10, marginBottom: 10, fontSize: 16 }}>
            <input
              type="text"
              placeholder={i18n.chats.search}
              required
              value={findValue || ''}
              style={{ width: '100%', border: 0, borderBottom: '1px solid #bbbbbb', fontSize: 18, height: 40 }}
              onChange={(e) => setFindValue(e.target.value)}
              className='text-area-modal input-modal'
            />
          </div>
          <AllConversationsList
            conversations={conversationsAvailableAuditor}
            transferringConvs={convTransferIds}
            selectedChat={selectedChatId}
            i18n={i18n}
            convId='id'
            chatClickHandler={(id) => {
              setScrollAllowed(true);
              auditorSubscribeToConversation(id);
              addSubscribedAuditorConv(id);
            }}
            newMessages={newMessages}
          />
        </>
      ) :
        <div />
      }
      {!auditor && <ButtonAcceptChats onClick={setAgentStatusHandler} agentStatus={agent ? agent.status : AGENT_STATUS.HALTING} />}
      <ModalBannedUsers
        onCancel={() => setShowBannedListModal(false)}
        onUnbanUser={handleUnbanUser}
        bannedUsersList={bannedUsers}
        show={showBannedListModal}
        title={i18n.chats.banConversationList}
        unbanTooltip={i18n.chats.unbanTooltip}
        cancelLabel={i18n.buttons.cancel}
      />
    </ChatStyled>
  );
};

Chat.defaultProps = {
  onChatClick: () => {},
};

Chat.propTypes = {
  i18n: PropTypes.object,
  onChatClick: PropTypes.func,
};

export default withI18n(Chat);
