import React, { useRef, useCallback, useState } from 'react';
import _, { debounce } from 'lodash';
import ConvsPanelStyled from './styled';
import { useSocketContext } from '../../../sockets/context';
import { ContactsService, AuthService } from '../../../services';
import TableComponent from '../../molecules/table-convs-sticky/view';
import i18n from '../../../assets/i18n';
/* import { sortAgents } from './utils'; */


const ConvsPanel = () => {

  const [conversations, setConversations] = useState([]);
  const { supervisorAgentStatus } = useSocketContext();

  const fetchMoreData = useCallback(async (lastId) => {
    try {
      const applicationId = await AuthService.getApplicationId();
      const data = await ContactsService.getContacts(applicationId, 100, null, lastId);
      return data;
    }
    catch (error) {
      throw new Error(error);
    }
  }, []);
 
  const searchConversationsByConvId = async (find, lastId) => {
    try {
      const applicationId =  await AuthService.getApplicationId();
      const encodedFind = encodeURIComponent(find);
      const data = await ContactsService.getContacts(applicationId, 100, encodedFind, lastId);
      setConversations(data || []);
    } catch (error) {
      throw new Error(error);
    }
  };
  
  const debouncedSearchConversationsByConvId = useRef(
    debounce((find, lastId) => searchConversationsByConvId(find, lastId), 900)
  ).current;


  return (
    <ConvsPanelStyled>
      {supervisorAgentStatus && (
      <TableComponent rows={conversations} setRows={setConversations} i18n={i18n} agents={supervisorAgentStatus} fetchMoreData={fetchMoreData} findData={debouncedSearchConversationsByConvId} />
      )}
      {/* orderedAgents.map(agentData => (
        <AgentDataCard key={agentData.agentId} data={agentData} />
      )) */}
    </ConvsPanelStyled>
  );
};

export default ConvsPanel;
